/* 2022-02-24 인수인계
사용하지 않는 파일입니다. */
.fade-enter {
    opacity: 0;
    transform: scale(1.02);
    overflow: hidden;
}

.fade-enter-active {
    opacity: 1;
    transform: scale(1);
    transition: opacity 200ms, transform 200ms;
    overflow: hidden;
}

.fade-exit {
    opacity: 1;
    transform: scale(1);
    overflow: hidden;
}

.fade-exit-active {
    opacity: 0;
    transform: scale(0.98);
    transition: opacity 200ms, transform 200ms;
    overflow: hidden;
}
