/* 2022-02-24 인수인계
검색 모듈(일정모아보기, 사용자검색등) css 입니다. */

div.module_wrapper {
}
div.module_wrapper.search_module {
    width: 100%;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    background: #fff;
    padding-top: 8px;
    display: none;
    width: 320px;
    left: 60px;
}
div.module_wrapper.search_module div.top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 8px;
    gap: 15px;
    width: 100%;
}
div.module_wrapper.search_module div.top div.left {
    width: calc(100% - 41px);
}
div.module_wrapper.search_module div.top div.left div.search_input_wrapper {
    background: #f2f2f2;
    height: 36px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 11px;
}
div.module_wrapper.search_module div.top div.left div.search_input_wrapper .inner_col {
    display: flex;
    gap: 8px;
}
div.module_wrapper.search_module div.top div.left .search_input_wrapper img {
    cursor: pointer;
}
div.module_wrapper.search_module div.top div.left .search_input_wrapper p.del_text {
    width: 16px;
    height: 16px;
    background: url(../images/icon_close_s_w.svg) no-repeat;
    background-color: #bbbbbb;
    border-radius: 100%;
    background-position: center;
    cursor: pointer;
}
div.module_wrapper.search_module div.top span {
    min-width: 41px;
    cursor: pointer;
    font-size: 14px;
    padding-left: 2px;
}
div.module_wrapper.search_module div.recent {
    display: none;
    width: 100%;
}
div.module_wrapper.search_module div.recent div.row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    height: 36px;
    margin-top: 15px;
}
div.module_wrapper.search_module div.recent div.row h1 {
    font-size: 12px;
    color: #bbbbbb;
}
div.module_wrapper.search_module div.recent div.row span.del_all {
    font-size: 12px;
    cursor: pointer;
    text-decoration: underline;
    color: #bbbbbb;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
div.module_wrapper.search_module div.recent ul.recent_search_list {
    padding: 0 24px;
    max-height: calc(100vh - 150px);
    overflow: auto;
    width: 100%;
    height: 100%;
}
div.module_wrapper.search_module div.recent ul.recent_search_list li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 43px;
    border-bottom: 1px solid #f2f2f2;
}
div.module_wrapper.search_module div.recent ul.recent_search_list h1 {
    font-size: 14px;
    cursor: pointer;
}
div.module_wrapper.search_module div.recent ul.recent_search_list p.del_recent_each_button {
    width: 16px;
    height: 16px;
    background: url(../images/icon_close_s_w.svg) no-repeat;
    background-color: #bbbbbb;
    border-radius: 100%;
    background-position: center;
    cursor: pointer;
}

#search_input {
    font-size: 14px;
}
#search_input:placeholder {
    color: #bbbbbb;
}
div.search_result_wrapper {
    padding: 0 20px;
    margin-top: 15px;
    display: none;
}
div.search_result_wrapper ul:nth-of-type(1) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #f2f2f2;
    height: 36px;
    border-radius: 8px;
    padding: 2px;
}
div.search_result_wrapper ul:nth-of-type(1) li.active {
    height: 100%;
    background: #fff;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #111111 !important;
}
div.search_result_wrapper ul:nth-of-type(1) li {
    width: 50%;
    text-align: center;
    font-size: 12px;
    color: #bbbbbb;
    cursor: pointer;
}
div.search_result_wrapper ul.search_result_list {
    /* cursor: pointer; */
    max-height: calc(100vh - 36px - 36px - 15px - 8px);
    overflow-y: auto;
    padding:10px 0 0
}
div.search_result_wrapper ul.search_result_list li {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
    padding: 12px 0;
    padding-bottom: 0;
}
div.search_result_wrapper ul.search_result_list li p.profile {
    width: 44px;
    height: 44px;
    border-radius: 100%;
    min-width: 44px;
    background: url(../images/icon_profile_basic.svg) no-repeat;
    min-height: 44px;
    background-size: cover !important;
    background-position: center !important;
}
div.search_result_wrapper ul.search_result_list li div.textArea {
    border-bottom: 1px solid #f2f2f2;
    width: 100%;
    padding: 12px 0;
}
div.search_result_wrapper ul.search_result_list li.new_search_result_item_2{
    padding-bottom: 12px;
}
div.search_result_wrapper ul.search_result_list li.new_search_result_item_2 div.textArea{
    border: 0;
}

div.search_result_wrapper ul.search_result_list li h1 {
    font-weight: 500;
    font-size: 14px;
    display: inline;
}
div.search_result_wrapper ul.search_result_list li span {
    font-size: 12px;
    color: #888888;
    font-weight: 300;
    width: 100%;
    word-break: break-all;
    display: flex;
}
div.search_result_wrapper ul.search_result_list li p.profile_2 {
    width: 44px;
    height: 44px;
    border-radius: 100%;
    min-width: 44px;
    background: url(../images/icon_teamwork_basic.svg) no-repeat;
    min-height: 44px;
    background-size: cover !important;
    background-position: center !important;
}
.search_module_search_wrapper {
}

.highlight {
    color: #4587f5;
    display: inline;
}
@media (max-width: 1023px) {
    div.module_wrapper.search_module{left:0}
}
@media (max-width: 768px) {
    div.module_wrapper.search_module {
        width: 100%;
        height: 100vh;
        position: fixed;
        left: 0;
        top: 0;
        background: #fff;
        padding-top: 8px;
        display: none;
    }
}
