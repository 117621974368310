/* 2022-02-24 인수인계
노말라이즈 css파일입니다. */

* {
    box-sizing: border-box;
    font-family: "noto_sans", "sans-serif";
}

#wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    flex-direction: column;
}
*:not(input):not(textarea) {
    -webkit-user-select: none !important; /* disable selection/Copy of UIWebView */
    -webkit-touch-callout: none !important; /* disable the IOS popup when long-press on a link */
}
input {
    -webkit-appearance: none;
}

input::-webkit-search-decoration,
input::-webkit-search-cancel-button,
input::-webkit-search-results-button,
input::-webkit-search-results-decoration{
    display:none;
}

@media (max-width: 10000px) {
    html,
    body {
        height: 100%;
    }
}

html,
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
button,
input,
textarea,
p,
select,
blockquote,
th,
td,
img,
a {
    margin: 0;
    padding: 0;
    font-weight: 400;
    color: #000;
}

b {
    font-weight: normal;
    font-family: noto_B;
}

li {
    list-style: none;
}

a {
    text-decoration: none;
    color: #333;
    cursor: pointer;
}

h1,
h2,
p {
    color: #414042;
}

a:hover {
}

a:active {
    text-decoration: none;
}

table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
}

img {
    display: block;
    border: 0;
}

button {
    background: transparent;
    border: 0;
    cursor: pointer;
    outline: none;
}

input,
select,
textarea {
    border: 0;
    background: none;
    outline: none;
    border-radius: 0;
}

/*hide input number buttons*/
input[type="number"] {
    -moz-appearance: textfield;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-transition: background-color 9999s ease-out;
    -webkit-box-shadow: none !important;
    -webkit-text-fill-color: inherit !important;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input {
}

select{-webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    background-image: url(../images/selIcon.svg) !important;
    background-position: center right 0 !important;
    background-size: 10px !important;
    background-repeat: no-repeat !important;
    padding-right: 15px;
}

@font-face {
    font-family: "noto_sans";
    font-weight: 100;
    font-style: normal;
    src: url("../fonts/noto_sans/NotoSans-Thin.eot?") format("eot"), url("../fonts/noto_sans/NotoSans-Thin.otf") format("opentype"), url("../fonts/noto_sans/NotoSans-Thin.woff") format("woff"), url("../fonts/noto_sans/NotoSans-Thin.woff2") format("woff2");
}

@font-face {
    font-family: "noto_sans";
    font-weight: 200;
    font-style: normal;
    src: url("../fonts/noto_sans/NotoSans-Light.eot?") format("eot"), url("../fonts/noto_sans/NotoSans-Light.otf") format("opentype"), url("../fonts/noto_sans/NotoSans-Light.woff") format("woff"), url("../fonts/noto_sans/NotoSans-Light.woff2") format("woff2");
}

@font-face {
    font-family: "noto_sans";
    font-weight: 300;
    font-style: normal;
    src: url("../fonts/noto_sans/NotoSans-DemiLight.eot?") format("eot"), url("../fonts/noto_sans/NotoSans-DemiLight.otf") format("opentype"), url("../fonts/noto_sans/NotoSans-DemiLight.woff") format("woff"), url("../fonts/noto_sans/NotoSans-DemiLight.woff2") format("woff2");
}

@font-face {
    font-family: "noto_sans";
    font-weight: 400;
    font-style: normal;
    src: url("../fonts/noto_sans/NotoSans-Regular.eot?") format("eot"), url("../fonts/noto_sans/NotoSans-Regular.otf") format("opentype"), url("../fonts/noto_sans/NotoSans-Regular.woff") format("woff"), url("../fonts/noto_sans/NotoSans-Regular.woff2") format("woff2");
}

@font-face {
    font-family: "noto_sans";
    font-weight: 500;
    font-style: normal;
    src: url("../fonts/noto_sans/NotoSans-Medium.eot?") format("eot"), url("../fonts/noto_sans/NotoSans-Medium.otf") format("opentype"), url("../fonts/noto_sans/NotoSans-Medium.woff") format("woff"), url("../fonts/noto_sans/NotoSans-Medium.woff2") format("woff2");
}

@font-face {
    font-family: "noto_sans";
    font-weight: 700;
    font-style: normal;
    src: url("../fonts/noto_sans/NotoSans-Black.eot?") format("eot"), url("../fonts/noto_sans/NotoSans-Black.otf") format("opentype"), url("../fonts/noto_sans/NotoSans-Black.woff") format("woff"), url("../fonts/noto_sans/NotoSans-Black.woff2") format("woff2");
}

@font-face {
    font-family: "noto_sans";
    font-weight: 900;
    font-style: normal;
    src: url("../fonts/noto_sans/NotoSans-Bold.eot?") format("eot"), url("../fonts/noto_sans/NotoSans-Bold.otf") format("opentype"), url("../fonts/noto_sans/NotoSans-Bold.woff") format("woff"), url("../fonts/noto_sans/NotoSans-Bold.woff2") format("woff2");
}

@font-face {
    font-family: "Spoqa Han Sans";
    font-weight: 700;
    font-display: swap;
    src: local("Spoqa Han Sans Bold"), url("../fonts/spoqa_neo/SpoqaHanSansNeo-Bold.woff2") format("woff2"), url("../fonts/spoqa_neo/SpoqaHanSansNeo-Bold.woff") format("woff"), url("../fonts/spoqa_neo/SpoqaHanSansNeo-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "Spoqa Han Sans";
    font-weight: 500;
    font-display: swap;
    src: local("Spoqa Han Sans Medium"), url("../fonts/spoqa_neo/SpoqaHanSansNeo-Medium.woff2") format("woff2"), url("../fonts/spoqa_neo/SpoqaHanSansNeo-Medium.woff") format("woff"), url("../fonts/spoqa_neo/SpoqaHanSansNeo-Medium.ttf") format("truetype");
}

@font-face {
    font-family: "Spoqa Han Sans";
    font-weight: 400;
    font-display: swap;
    src: local("Spoqa Han Sans Regular"), url("../fonts/spoqa_neo/SpoqaHanSansNeo-Regular.woff2") format("woff2"), url("../fonts/spoqa_neo/SpoqaHanSansNeo-Regular.woff") format("woff"), url("../fonts/spoqa_neo/SpoqaHanSansNeo-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Spoqa Han Sans";
    font-weight: 300;
    font-display: swap;
    src: local("Spoqa Han Sans Light"), url("../fonts/spoqa_neo/SpoqaHanSansNeo-Light.woff2") format("woff2"), url("../fonts/spoqa_neo/SpoqaHanSansNeo-Light.woff") format("woff"), url("../fonts/spoqa_neo/SpoqaHanSansNeo-Light.ttf") format("truetype");
}

@font-face {
    font-family: "Spoqa Han Sans";
    font-weight: 100;
    font-display: swap;
    src: local("Spoqa Han Sans Thin"), url("../fonts/spoqa_neo/SpoqaHanSansNeo-Thin.woff2") format("woff2"), url("../fonts/spoqa_neo/SpoqaHanSansNeo-Thin.woff") format("woff"), url("../fonts/spoqa_neo/SpoqaHanSansNeo-Thin.ttf") format("truetype");
}

@font-face {
    font-family: "Spoqa Han Sans Neo";
    font-weight: 700;
    font-display: swap;
    src: local("Spoqa Han Sans Neo Bold"), url("../fonts/spoqa_neo/SpoqaHanSansNeo-Bold.woff2") format("woff2"), url("../fonts/spoqa_neo/SpoqaHanSansNeo-Bold.woff") format("woff"), url("../fonts/spoqa_neo/SpoqaHanSansNeo-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "Spoqa Han Sans Neo";
    font-weight: 500;
    font-display: swap;
    src: local("Spoqa Han Sans Neo Medium"), url("../fonts/spoqa_neo/SpoqaHanSansNeo-Medium.woff2") format("woff2"), url("../fonts/spoqa_neo/SpoqaHanSansNeo-Medium.woff") format("woff"), url("../fonts/spoqa_neo/SpoqaHanSansNeo-Medium.ttf") format("truetype");
}

@font-face {
    font-family: "Spoqa Han Sans Neo";
    font-weight: 400;
    font-display: swap;
    src: local("Spoqa Han Sans Neo Regular"), url("../fonts/spoqa_neo/SpoqaHanSansNeo-Regular.woff2") format("woff2"), url("../fonts/spoqa_neo/SpoqaHanSansNeo-Regular.woff") format("woff"), url("../fonts/spoqa_neo/SpoqaHanSansNeo-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Spoqa Han Sans Neo";
    font-weight: 300;
    font-display: swap;
    src: local("Spoqa Han Sans Neo Light"), url("../fonts/spoqa_neo/SpoqaHanSansNeo-Light.woff2") format("woff2"), url("../fonts/spoqa_neo/SpoqaHanSansNeo-Light.woff") format("woff"), url("../fonts/spoqa_neo/SpoqaHanSansNeo-Light.ttf") format("truetype");
}

@font-face {
    font-family: "Spoqa Han Sans Neo";
    font-weight: 100;
    font-display: swap;
    src: local("Spoqa Han Sans Neo Thin"), url("../fonts/spoqa_neo/SpoqaHanSansNeo-Thin.woff2") format("woff2"), url("../fonts/spoqa_neo/SpoqaHanSansNeo-Thin.woff") format("woff"), url("../fonts/spoqa_neo/SpoqaHanSansNeo-Thin.ttf") format("truetype");
}

@media (max-width: 767px) {
    #wrap {
        justify-content: flex-start;
    }
}
