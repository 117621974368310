/* 2022-02-24 인수인계
버튼 ui css입니다. */

.button_type_1 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    position: static;
    width: 160px;
    height: 44px;
    background: #4587f5;
    border-radius: 1000px;
    margin: 8px 0px;
    color: #fff;
    font-size: 14px;
    margin-top: 40px;
}

.button_type_2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    max-width: 440px;
    width: calc(100% - 32px);
}
.button_type_2 button {
    width: 100%;
    height: 44px;
    background-color: #f2f2f2;
    border-radius: 8px;
}
.button_type_2 button a {
    color: #111111;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    font-size: 14px;
    font-weight: 500;
    border-radius: 8px;
}

.button_type_3 {
    background: #f2f2f2;
    max-width: 440px;
    width: calc(100% - 32px);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 500;
    height: 44px;
    border-radius: 8px;
    cursor: pointer;
    margin-top: 40px;
}
