/* 2022-02-24 인수인계
캘린더페이지 (/cal) css입니다. */
.next_sch {
    display: none;
}

.fc-daygrid-day-number {
    width: 44px;
    height: 44px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.fc .fc-view-harness {
    height: 600px !important;
}
@media (max-width: 767px) {
    .next_sch {
        display: flex;
    }
    .fc-daygrid-day-number,
    .fc-daygrid-day.fc-day-today .fc-daygrid-day-number {
        width: 33px;
        height: 33px;
    }
}
.status_1,
.status_2,
.status_3,
.status_4,
.status_5 {
    margin: 0 !important;
}

div.chatting_area {
    padding-top: 0;
}

.fc .fc-daygrid-event-harness a {
    min-width: 5px !important;
    min-height: 5px !important;
    max-height: 5px;
    margin: 0 !important;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}

.fc .fc-daygrid-day-frame {
    flex-direction: column;
}
