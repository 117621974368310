/* 2022-02-24 인수인계
약관 css입니다. */

ol.lst-kix_list_7-0 {
    list-style-type: none;
}
.lst-kix_list_3-0 > li:before {
    content: "-  ";
}
ul.lst-kix_list_5-7 {
    list-style-type: none;
}
ul.lst-kix_list_5-8 {
    list-style-type: none;
}
.lst-kix_list_3-1 > li:before {
    content: "\002022  ";
}
.lst-kix_list_3-2 > li:before {
    content: "\002022  ";
}
ul.lst-kix_list_5-5 {
    list-style-type: none;
}
ul.lst-kix_list_5-6 {
    list-style-type: none;
}
.lst-kix_list_8-1 > li:before {
    content: "-  ";
}
.lst-kix_list_8-2 > li:before {
    content: "\002022  ";
}
.lst-kix_list_5-0 > li {
    counter-increment: lst-ctn-kix_list_5-0;
}
.lst-kix_list_6-0 > li {
    counter-increment: lst-ctn-kix_list_6-0;
}
.lst-kix_list_7-0 > li {
    counter-increment: lst-ctn-kix_list_7-0;
}
.lst-kix_list_8-0 > li {
    counter-increment: lst-ctn-kix_list_8-0;
}
ul.lst-kix_list_1-3 {
    list-style-type: none;
}
.lst-kix_list_3-5 > li:before {
    content: "\002022  ";
}
ul.lst-kix_list_1-4 {
    list-style-type: none;
}
ul.lst-kix_list_1-1 {
    list-style-type: none;
}
.lst-kix_list_3-4 > li:before {
    content: "\002022  ";
}
ul.lst-kix_list_1-2 {
    list-style-type: none;
}
ul.lst-kix_list_5-3 {
    list-style-type: none;
}
ul.lst-kix_list_1-7 {
    list-style-type: none;
}
.lst-kix_list_3-3 > li:before {
    content: "\002022  ";
}
ul.lst-kix_list_5-4 {
    list-style-type: none;
}
ul.lst-kix_list_1-8 {
    list-style-type: none;
}
ul.lst-kix_list_5-1 {
    list-style-type: none;
}
.lst-kix_list_8-0 > li:before {
    content: "" counter(lst-ctn-kix_list_8-0, decimal) ". ";
}
ul.lst-kix_list_1-5 {
    list-style-type: none;
}
ul.lst-kix_list_5-2 {
    list-style-type: none;
}
ul.lst-kix_list_1-6 {
    list-style-type: none;
}
.lst-kix_list_8-7 > li:before {
    content: "\002022  ";
}
.lst-kix_list_3-8 > li:before {
    content: "\002022  ";
}
.lst-kix_list_8-5 > li:before {
    content: "\002022  ";
}
.lst-kix_list_8-6 > li:before {
    content: "\002022  ";
}
.lst-kix_list_8-3 > li:before {
    content: "\002022  ";
}
.lst-kix_list_3-6 > li:before {
    content: "\002022  ";
}
.lst-kix_list_3-7 > li:before {
    content: "\002022  ";
}
.lst-kix_list_8-4 > li:before {
    content: "\002022  ";
}
ol.lst-kix_list_5-0.start {
    counter-reset: lst-ctn-kix_list_5-0 0;
}
.lst-kix_list_8-8 > li:before {
    content: "\002022  ";
}
.lst-kix_list_5-0 > li:before {
    content: "" counter(lst-ctn-kix_list_5-0, decimal) ") ";
}
ol.lst-kix_list_6-0 {
    list-style-type: none;
}
.lst-kix_list_4-8 > li:before {
    content: "\002022  ";
}
.lst-kix_list_5-3 > li:before {
    content: "\002022  ";
}
.lst-kix_list_4-7 > li:before {
    content: "\002022  ";
}
.lst-kix_list_5-2 > li:before {
    content: "\002022  ";
}
.lst-kix_list_5-1 > li:before {
    content: "\002022  ";
}
li.li-bullet-6:before {
    margin-left: -8.4pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 8.4pt;
}
li.li-bullet-7:before {
    margin-left: -5.6pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 5.6pt;
}
ul.lst-kix_list_4-8 {
    list-style-type: none;
}
.lst-kix_list_5-7 > li:before {
    content: "\002022  ";
}
ul.lst-kix_list_8-4 {
    list-style-type: none;
}
ul.lst-kix_list_8-5 {
    list-style-type: none;
}
ul.lst-kix_list_4-6 {
    list-style-type: none;
}
.lst-kix_list_5-6 > li:before {
    content: "\002022  ";
}
.lst-kix_list_5-8 > li:before {
    content: "\002022  ";
}
ul.lst-kix_list_8-2 {
    list-style-type: none;
}
ul.lst-kix_list_4-7 {
    list-style-type: none;
}
ul.lst-kix_list_8-3 {
    list-style-type: none;
}
ul.lst-kix_list_8-8 {
    list-style-type: none;
}
ul.lst-kix_list_8-6 {
    list-style-type: none;
}
ul.lst-kix_list_8-7 {
    list-style-type: none;
}
ul.lst-kix_list_4-0 {
    list-style-type: none;
}
ul.lst-kix_list_4-1 {
    list-style-type: none;
}
.lst-kix_list_5-4 > li:before {
    content: "\002022  ";
}
ul.lst-kix_list_4-4 {
    list-style-type: none;
}
.lst-kix_list_5-5 > li:before {
    content: "\002022  ";
}
ul.lst-kix_list_4-5 {
    list-style-type: none;
}
ul.lst-kix_list_8-1 {
    list-style-type: none;
}
ul.lst-kix_list_4-2 {
    list-style-type: none;
}
ul.lst-kix_list_4-3 {
    list-style-type: none;
}
ol.lst-kix_list_1-0.start {
    counter-reset: lst-ctn-kix_list_1-0 1;
}
.lst-kix_list_6-1 > li:before {
    content: "\002022  ";
}
.lst-kix_list_6-3 > li:before {
    content: "\002022  ";
}
.lst-kix_list_6-0 > li:before {
    content: "" counter(lst-ctn-kix_list_6-0, decimal) ") ";
}
.lst-kix_list_6-4 > li:before {
    content: "\002022  ";
}
li.li-bullet-2:before {
    margin-left: -8.2pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 8.2pt;
}
.lst-kix_list_6-2 > li:before {
    content: "\002022  ";
}
.lst-kix_list_6-8 > li:before {
    content: "\002022  ";
}
ol.lst-kix_list_8-0.start {
    counter-reset: lst-ctn-kix_list_8-0 0;
}
.lst-kix_list_6-5 > li:before {
    content: "\002022  ";
}
.lst-kix_list_6-7 > li:before {
    content: "\002022  ";
}
.lst-kix_list_7-0 > li:before {
    content: "(" counter(lst-ctn-kix_list_7-0, decimal) ") ";
}
.lst-kix_list_6-6 > li:before {
    content: "\002022  ";
}
ol.lst-kix_list_5-0 {
    list-style-type: none;
}
.lst-kix_list_2-6 > li:before {
    content: "\002022  ";
}
.lst-kix_list_2-7 > li:before {
    content: "\002022  ";
}
.lst-kix_list_7-4 > li:before {
    content: "\002022  ";
}
.lst-kix_list_7-6 > li:before {
    content: "\002022  ";
}
ol.lst-kix_list_1-0 {
    list-style-type: none;
}
.lst-kix_list_2-4 > li:before {
    content: "\002022  ";
}
.lst-kix_list_2-5 > li:before {
    content: "\002022  ";
}
.lst-kix_list_2-8 > li:before {
    content: "\002022  ";
}
.lst-kix_list_7-1 > li:before {
    content: "\002022  ";
}
.lst-kix_list_7-5 > li:before {
    content: "\002022  ";
}
.lst-kix_list_7-2 > li:before {
    content: "\002022  ";
}
.lst-kix_list_7-3 > li:before {
    content: "\002022  ";
}
ul.lst-kix_list_7-5 {
    list-style-type: none;
}
ul.lst-kix_list_7-6 {
    list-style-type: none;
}
ul.lst-kix_list_7-3 {
    list-style-type: none;
}
ul.lst-kix_list_3-7 {
    list-style-type: none;
}
ul.lst-kix_list_7-4 {
    list-style-type: none;
}
ul.lst-kix_list_3-8 {
    list-style-type: none;
}
ul.lst-kix_list_7-7 {
    list-style-type: none;
}
ul.lst-kix_list_7-8 {
    list-style-type: none;
}
ul.lst-kix_list_3-1 {
    list-style-type: none;
}
ul.lst-kix_list_3-2 {
    list-style-type: none;
}
.lst-kix_list_7-8 > li:before {
    content: "\002022  ";
}
ul.lst-kix_list_3-0 {
    list-style-type: none;
}
ul.lst-kix_list_7-1 {
    list-style-type: none;
}
ul.lst-kix_list_3-5 {
    list-style-type: none;
}
ul.lst-kix_list_7-2 {
    list-style-type: none;
}
ul.lst-kix_list_3-6 {
    list-style-type: none;
}
ul.lst-kix_list_3-3 {
    list-style-type: none;
}
.lst-kix_list_7-7 > li:before {
    content: "\002022  ";
}
ul.lst-kix_list_3-4 {
    list-style-type: none;
}
li.li-bullet-1:before {
    margin-left: -6.3pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 6.3pt;
}
.lst-kix_list_4-0 > li:before {
    content: "-  ";
}
.lst-kix_list_4-1 > li:before {
    content: "\002022  ";
}
li.li-bullet-3:before {
    margin-left: -4.2pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 4.2pt;
}
.lst-kix_list_4-4 > li:before {
    content: "\002022  ";
}
.lst-kix_list_4-3 > li:before {
    content: "\002022  ";
}
.lst-kix_list_4-5 > li:before {
    content: "\002022  ";
}
.lst-kix_list_4-2 > li:before {
    content: "\002022  ";
}
.lst-kix_list_4-6 > li:before {
    content: "\002022  ";
}
ol.lst-kix_list_7-0.start {
    counter-reset: lst-ctn-kix_list_7-0 0;
}
li.li-bullet-8:before {
    margin-left: -5.6pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 5.6pt;
}
li.li-bullet-9:before {
    margin-left: -5.6pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 5.6pt;
}
ul.lst-kix_list_6-6 {
    list-style-type: none;
}
li.li-bullet-5:before {
    margin-left: -4.2pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 4.2pt;
}
ul.lst-kix_list_6-7 {
    list-style-type: none;
}
ul.lst-kix_list_6-4 {
    list-style-type: none;
}
ul.lst-kix_list_2-8 {
    list-style-type: none;
}
ul.lst-kix_list_6-5 {
    list-style-type: none;
}
ul.lst-kix_list_6-8 {
    list-style-type: none;
}
ul.lst-kix_list_2-2 {
    list-style-type: none;
}
.lst-kix_list_1-0 > li:before {
    content: "(" counter(lst-ctn-kix_list_1-0, decimal) ") ";
}
ul.lst-kix_list_2-3 {
    list-style-type: none;
}
ul.lst-kix_list_2-0 {
    list-style-type: none;
}
ul.lst-kix_list_2-1 {
    list-style-type: none;
}
ul.lst-kix_list_6-2 {
    list-style-type: none;
}
ol.lst-kix_list_8-0 {
    list-style-type: none;
}
ul.lst-kix_list_2-6 {
    list-style-type: none;
}
ul.lst-kix_list_6-3 {
    list-style-type: none;
}
.lst-kix_list_1-1 > li:before {
    content: "-  ";
}
.lst-kix_list_1-2 > li:before {
    content: "\002022  ";
}
ul.lst-kix_list_2-7 {
    list-style-type: none;
}
ul.lst-kix_list_2-4 {
    list-style-type: none;
}
ul.lst-kix_list_6-1 {
    list-style-type: none;
}
ul.lst-kix_list_2-5 {
    list-style-type: none;
}
.lst-kix_list_1-3 > li:before {
    content: "\002022  ";
}
.lst-kix_list_1-4 > li:before {
    content: "\002022  ";
}
.lst-kix_list_1-0 > li {
    counter-increment: lst-ctn-kix_list_1-0;
}
.lst-kix_list_1-7 > li:before {
    content: "\002022  ";
}
.lst-kix_list_1-5 > li:before {
    content: "\002022  ";
}
.lst-kix_list_1-6 > li:before {
    content: "\002022  ";
}
li.li-bullet-0:before {
    margin-left: -12.5pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 12.5pt;
}
li.li-bullet-4:before {
    margin-left: -3.7pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 3.7pt;
}
.lst-kix_list_2-0 > li:before {
    content: "-  ";
}
.lst-kix_list_2-1 > li:before {
    content: "\002022  ";
}
ol.lst-kix_list_6-0.start {
    counter-reset: lst-ctn-kix_list_6-0 0;
}
.lst-kix_list_1-8 > li:before {
    content: "\002022  ";
}
.lst-kix_list_2-2 > li:before {
    content: "\002022  ";
}
.lst-kix_list_2-3 > li:before {
    content: "\002022  ";
}
ol {
    margin: 0;
    padding: 0;
}
table td,
table th {
    padding: 0;
}
.c14 {
    border-right-style: solid;
    border-bottom-color: #000000;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 1pt;
    border-top-style: solid;
    background-color: #fae3d4;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 99.2pt;
    border-top-color: #000000;
    border-bottom-style: solid;
}
.c25 {
    border-right-style: solid;
    border-bottom-color: #000000;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 304.9pt;
    border-top-color: #000000;
    border-bottom-style: solid;
}
.c10 {
    border-right-style: solid;
    border-bottom-color: #000000;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 157.3pt;
    border-top-color: #000000;
    border-bottom-style: solid;
}
.c46 {
    border-right-style: solid;
    border-bottom-color: #000000;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 56.7pt;
    border-top-color: #000000;
    border-bottom-style: solid;
}
.c17 {
    border-right-style: solid;
    border-bottom-color: #000000;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 99.2pt;
    border-top-color: #000000;
    border-bottom-style: solid;
}
.c4 {
    border-right-style: solid;
    border-bottom-color: #000000;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 196.8pt;
    border-top-color: #000000;
    border-bottom-style: solid;
}
.c29 {
    border-right-style: solid;
    border-bottom-color: #000000;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 106.3pt;
    border-top-color: #000000;
    border-bottom-style: solid;
}
.c34 {
    border-right-style: solid;
    border-bottom-color: #000000;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 212.7pt;
    border-top-color: #000000;
    border-bottom-style: solid;
}
.c24 {
    margin-left: 5.3pt;
    padding-top: 0.5pt;
    list-style-position: inside;
    text-indent: 45pt;
    padding-bottom: 0pt;
    line-height: 1.1500000000000001;
    text-align: left;
    margin-right: 7.5pt;
}
.c23 {
    margin-left: 20.3pt;
    padding-top: 0.1pt;
    text-indent: 0.8pt;
    padding-bottom: 0pt;
    line-height: 1.1500000000000001;
    text-align: left;
    margin-right: 4.5pt;
    height: 11pt;
}
.c77 {
    margin-left: 8.2pt;
    padding-top: 7.8pt;
    text-indent: -0.5pt;
    padding-bottom: 0pt;
    line-height: 1.1500000000000001;
    text-align: center;
    margin-right: 7.2pt;
}
.c58 {
    margin-left: 29.8pt;
    padding-top: 0pt;
    text-indent: -7.8pt;
    padding-bottom: 0pt;
    line-height: 1.1500000000000001;
    text-align: left;
    margin-right: 9.7pt;
}
.c19 {
    margin-left: 6.2pt;
    padding-top: 3.6pt;
    padding-bottom: 0pt;
    line-height: 1.1500000000000001;
    text-align: left;
    margin-right: 6.2pt;
    height: 11pt;
}
.c9 {
    margin-left: 24.1pt;
    padding-top: 0pt;
    padding-left: -9.6pt;
    padding-bottom: 0pt;
    line-height: 1.1500000000000001;
    text-align: left;
    margin-right: 140.8pt;
}
.c7 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 8pt;
    font-family: "Malgun Gothic";
    font-style: normal;
}
.c1 {
    color: #000000;
    font-weight: 700;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 8pt;
    font-family: "Malgun Gothic";
    font-style: normal;
}
.c80 {
    margin-left: 26.6pt;
    padding-top: 0.1pt;
    padding-left: -5.5pt;
    padding-bottom: 0pt;
    line-height: 1.1500000000000001;
    text-align: left;
    margin-right: 346.1pt;
}
.c6 {
    color: #0033cc;
    font-weight: 700;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 8pt;
    font-family: "Malgun Gothic";
    font-style: normal;
}
.c74 {
    margin-left: 20.3pt;
    padding-top: 0.2pt;
    text-indent: 0.1pt;
    padding-bottom: 0pt;
    line-height: 1.1500000000000001;
    text-align: left;
    margin-right: 15.8pt;
}
.c5 {
    color: #000000;
    font-weight: 700;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 12pt;
    font-family: "Malgun Gothic";
    font-style: normal;
}
.c44 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: "Malgun Gothic";
    font-style: normal;
}
.c0 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 12pt;
    font-family: "Malgun Gothic";
    font-style: normal;
    margin-top: 10px;
}
.c8 {
    margin-left: 8.2pt;
    padding-top: 0pt;
    text-indent: -0.5pt;
    padding-bottom: 0pt;
    line-height: 1.1500000000000001;
    text-align: center;
    margin-right: 7.2pt;
}
.c47 {
    margin-left: 21.1pt;
    padding-top: 0pt;
    text-indent: 9.9pt;
    padding-bottom: 0pt;
    line-height: 1.1500000000000001;
    text-align: left;
    margin-right: 10.2pt;
}
.c54 {
    margin-left: 20.3pt;
    padding-top: 0.1pt;
    text-indent: 0.8pt;
    padding-bottom: 0pt;
    line-height: 1.1500000000000001;
    text-align: left;
    margin-right: 4.5pt;
}
.c71 {
    margin-left: 29.8pt;
    padding-top: 0pt;
    text-indent: -7.8pt;
    padding-bottom: 0pt;
    line-height: 1.1500000000000001;
    text-align: left;
    margin-right: 5.9pt;
}
.c64 {
    margin-left: 8.2pt;
    padding-top: 0.1pt;
    text-indent: -0.5pt;
    padding-bottom: 0pt;
    line-height: 1.1500000000000001;
    text-align: center;
    margin-right: 7.2pt;
}
.c32 {
    margin-left: 65.6pt;
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.1500000000000001;
    text-align: center;
    margin-right: 65pt;
}
.c55 {
    margin-left: 78.2pt;
    padding-top: 0.1pt;
    padding-bottom: 0pt;
    line-height: 1.1500000000000001;
    text-align: center;
    margin-right: 77.9pt;
}
.c69 {
    margin-left: 19.8pt;
    padding-top: 0pt;
    text-indent: -10.8pt;
    padding-bottom: 0pt;
    line-height: 1.1500000000000001;
    text-align: left;
}
.c21 {
    margin-left: 28.6pt;
    padding-top: 0pt;
    padding-left: -11.7pt;
    padding-bottom: 0pt;
    line-height: 1.1500000000000001;
    text-align: left;
}
.c62 {
    margin-left: 18.9pt;
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.1500000000000001;
    text-align: center;
    margin-right: 18.6pt;
}
.c70 {
    margin-left: 135.6pt;
    padding-top: 0.1pt;
    padding-bottom: 0pt;
    line-height: 1.1500000000000001;
    text-align: center;
    margin-right: 134.6pt;
}
.c45 {
    margin-left: 26.6pt;
    padding-top: 0pt;
    padding-left: -11.7pt;
    padding-bottom: 0pt;
    line-height: 1.1500000000000001;
    text-align: left;
}
.c79 {
    margin-left: 23.3pt;
    padding-top: 0pt;
    padding-left: -14.3pt;
    padding-bottom: 0pt;
    line-height: 1.1500000000000001;
    text-align: left;
}
.c65 {
    margin-left: 19.1pt;
    padding-top: 0.1pt;
    padding-bottom: 0pt;
    line-height: 1.1500000000000001;
    text-align: center;
    margin-right: 18.6pt;
}
.c66 {
    margin-left: 23.3pt;
    padding-top: 6.4pt;
    padding-left: -13.8pt;
    padding-bottom: 0pt;
    line-height: 1.1500000000000001;
    text-align: left;
}
.c59 {
    margin-left: 16.1pt;
    padding-top: 0.2pt;
    padding-bottom: 0pt;
    line-height: 1.1500000000000001;
    text-align: left;
    margin-right: 154.3pt;
}
.c83 {
    margin-left: 7pt;
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.1500000000000001;
    text-align: left;
    margin-right: 3pt;
}
.c60 {
    margin-left: 91.2pt;
    padding-top: 0.1pt;
    padding-bottom: 0pt;
    line-height: 1.1500000000000001;
    text-align: center;
    margin-right: 90.8pt;
}
.c67 {
    margin-left: 23.3pt;
    padding-top: 0pt;
    padding-left: -13.8pt;
    padding-bottom: 0pt;
    line-height: 1.1500000000000001;
    text-align: left;
}
.c84 {
    margin-left: 6.2pt;
    padding-top: 3.6pt;
    padding-bottom: 0pt;
    line-height: 1.1500000000000001;
    text-align: left;
    margin-right: 6.2pt;
}
.c3 {
    margin-left: 65.6pt;
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.1500000000000001;
    text-align: center;
    margin-right: 65.1pt;
}
.c81 {
    margin-left: 65.6pt;
    padding-top: 0.1pt;
    padding-bottom: 0pt;
    line-height: 1.1500000000000001;
    text-align: center;
    margin-right: 65.2pt;
}
.c26 {
    margin-left: 38.4pt;
    padding-top: 0pt;
    padding-left: -12.4pt;
    padding-bottom: 0pt;
    line-height: 1.1500000000000001;
    text-align: left;
}
.c13 {
    margin-left: 19.1pt;
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.1500000000000001;
    text-align: center;
    margin-right: 18.6pt;
}
.c68 {
    margin-left: 6.1pt;
    padding-top: 3.6pt;
    padding-bottom: 0pt;
    line-height: 1.1500000000000001;
    text-align: left;
    margin-right: 6.2pt;
}
.c42 {
    margin-left: 24.1pt;
    padding-top: 0pt;
    padding-left: -9.8pt;
    padding-bottom: 0pt;
    line-height: 1.1500000000000001;
    text-align: left;
}
.c82 {
    margin-left: 19.8pt;
    padding-top: 8pt;
    text-indent: -10.8pt;
    padding-bottom: 0pt;
    line-height: 1.1500000000000001;
    text-align: left;
}
.c72 {
    margin-left: 6.2pt;
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.1500000000000001;
    text-align: left;
    margin-right: 15.8pt;
}
.c56 {
    margin-left: 23.3pt;
    padding-top: 6.3pt;
    padding-left: -13.8pt;
    padding-bottom: 0pt;
    line-height: 1.1500000000000001;
    text-align: left;
}
.c51 {
    margin-left: 7.2pt;
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.1500000000000001;
    text-align: justify;
    margin-right: 4.1pt;
}
.c52 {
    margin-left: 19.8pt;
    padding-top: 0.1pt;
    text-indent: -10.8pt;
    padding-bottom: 0pt;
    line-height: 1.1500000000000001;
    text-align: left;
}
.c36 {
    margin-left: 20.3pt;
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.1500000000000001;
    text-align: left;
}
.c18 {
    margin-left: 10.8pt;
    padding-top: 0.1pt;
    padding-bottom: 0pt;
    line-height: 1.1500000000000001;
    text-align: left;
}
.c16 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.15;
    text-align: left;
    height: 11pt;
}
.c63 {
    color: #0033cc;
    text-decoration: none;
    vertical-align: baseline;
    font-family: "Malgun Gothic";
    font-style: normal;
}
.c2 {
    padding-top: 1.9pt;
    padding-bottom: 0pt;
    line-height: 1.1500000000000001;
    text-align: left;
    margin-right: 258.7pt;
}
.c27 {
    padding-top: 0.1pt;
    padding-bottom: 0pt;
    line-height: 1.1500000000000001;
    text-align: left;
    height: 11pt;
}
.c61 {
    padding-top: 0.8pt;
    padding-bottom: 0pt;
    line-height: 1.1500000000000001;
    text-align: left;
    height: 11pt;
}
.c39 {
    margin-left: 16.1pt;
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.1500000000000001;
    text-align: left;
}
.c43 {
    padding-top: 0.3pt;
    padding-bottom: 0pt;
    line-height: 1.1500000000000001;
    text-align: left;
    height: 11pt;
}
.c11 {
    margin-left: 5.3pt;
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.1500000000000001;
    text-align: left;
}
.c49 {
    margin-left: 9.9pt;
    padding-top: 0.1pt;
    padding-bottom: 0pt;
    line-height: 1.1500000000000001;
    text-align: left;
}
.c28 {
    margin-left: 5.3pt;
    padding-top: 2.2pt;
    padding-bottom: 0pt;
    line-height: 1.1500000000000001;
    text-align: left;
}
.c22 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.1500000000000001;
    text-align: left;
    height: 11pt;
}
.c37 {
    margin-left: 5.3pt;
    padding-top: 2.3pt;
    padding-bottom: 0pt;
    line-height: 1.1500000000000001;
    text-align: left;
}
.c50 {
    padding-top: 0.6pt;
    padding-bottom: 0pt;
    line-height: 1.1500000000000001;
    text-align: left;
    height: 11pt;
}
.c76 {
    margin-left: 5.3pt;
    padding-top: 2.4pt;
    padding-bottom: 0pt;
    line-height: 1.1500000000000001;
    text-align: left;
}
.c41 {
    margin-left: 6.4pt;
    border-spacing: 0;
    border-collapse: collapse;
    margin-right: auto;
    margin: 0;
}
.c15 {
    margin-left: 6.7pt;
    border-spacing: 0;
    border-collapse: collapse;
    margin-right: auto;
    margin: 0;
}
.c38 {
    text-decoration-skip-ink: none;
    font-size: 12pt;
    -webkit-text-decoration-skip: none;
    text-decoration: underline;
    display: flex;
    margin-bottom: 17px;
    margin-bottom: 0 !important;
}
.c78 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1;
    text-align: left;
}
.c53 {
    background-color: #ffffff;
    max-width: 451.5pt;
    padding: 72pt 72pt 72pt 72pt;
}
.c30 {
    font-size: 12pt;
    font-weight: 700;
    margin-bottom: 10px;
    display: flex;
}
.c12 {
    padding: 0;
    margin: 0;
}
.c35 {
    height: 11.2pt;
}
.c33 {
    background-color: #fae3d4;
}
.c57 {
    height: 55.7pt;
}
.c20 {
    height: 15.4pt;
}
.c75 {
    height: 11pt;
}
.c48 {
    height: 15.6pt;
}
.c31 {
    height: 42.1pt;
}
.c73 {
    height: 11.3pt;
}
.c40 {
    font-weight: 700;
}

.c48 {
    padding-left: 1rem;
}
.agree_content .title {
    padding-top: 24pt;
    color: #000000;
    font-weight: 700;
    font-size: 36pt;
    padding-bottom: 6pt;
    font-family: "Malgun Gothic";
    line-height: 1;
    page-break-after: avoid;
    text-align: left;
}
.agree_content .subtitle {
    padding-top: 18pt;
    color: #666666;
    font-size: 24pt;
    padding-bottom: 4pt;
    font-family: "Georgia";
    line-height: 1;
    page-break-after: avoid;
    font-style: italic;
    text-align: left;
}
.agree_content li {
    color: #000000;
    font-size: 11pt;
    font-family: "Malgun Gothic";
}
div.agree_popup_wrapper div.agree_content * {
    max-width: 100%;
    line-height: 24px;
}
.agree_content p {
    margin: 0;
    color: #000000;
    font-size: 11pt;
    font-family: "Malgun Gothic";
}
.agree_content h1 {
    padding-top: 24pt;
    color: #000000;
    font-weight: 700;
    font-size: 24pt;
    padding-bottom: 6pt;
    font-family: "Malgun Gothic";
    line-height: 1;
    page-break-after: avoid;
    text-align: left;
}
.agree_content h2 {
    padding-top: 0pt;
    color: #000000;
    font-size: 8pt;
    padding-bottom: 0pt;
    font-family: "Malgun Gothic";
    line-height: 0.9458333333333333;
    text-align: left;
}
.agree_content h3 {
    padding-top: 0pt;
    color: #000000;
    font-weight: 700;
    font-size: 6pt;
    padding-bottom: 0pt;
    font-family: "Malgun Gothic";
    line-height: 1;
    text-align: left;
}
.agree_content h4 {
    padding-top: 12pt;
    color: #000000;
    font-weight: 700;
    font-size: 12pt;
    padding-bottom: 2pt;
    font-family: "Malgun Gothic";
    line-height: 1;
    page-break-after: avoid;
    text-align: left;
}
.agree_content h5 {
    padding-top: 11pt;
    color: #000000;
    font-weight: 700;
    font-size: 11pt;
    padding-bottom: 2pt;
    font-family: "Malgun Gothic";
    line-height: 1;
    page-break-after: avoid;
    text-align: left;
}
.agree_content h6 {
    padding-top: 10pt;
    color: #000000;
    font-weight: 700;
    font-size: 10pt;
    padding-bottom: 2pt;
    font-family: "Malgun Gothic";
    line-height: 1;
    page-break-after: avoid;
    text-align: left;
}

@media (max-width: 767px) {
    ol.lst-kix_list_7-0 {
        list-style-type: none;
    }
    .lst-kix_list_3-0 > li:before {
        content: "-  ";
    }
    ul.lst-kix_list_5-7 {
        list-style-type: none;
    }
    ul.lst-kix_list_5-8 {
        list-style-type: none;
    }
    .lst-kix_list_3-1 > li:before {
        content: "\002022  ";
    }
    .lst-kix_list_3-2 > li:before {
        content: "\002022  ";
    }
    ul.lst-kix_list_5-5 {
        list-style-type: none;
    }
    ul.lst-kix_list_5-6 {
        list-style-type: none;
    }
    .lst-kix_list_8-1 > li:before {
        content: "-  ";
    }
    .lst-kix_list_8-2 > li:before {
        content: "\002022  ";
    }
    .lst-kix_list_5-0 > li {
        counter-increment: lst-ctn-kix_list_5-0;
        margin: 0;
        margin-bottom: 1rem;
    }
    .lst-kix_list_6-0 > li {
        counter-increment: lst-ctn-kix_list_6-0;
        margin: 0;
    }
    .lst-kix_list_7-0 > li {
        counter-increment: lst-ctn-kix_list_7-0;
    }
    .lst-kix_list_8-0 > li {
        counter-increment: lst-ctn-kix_list_8-0;
    }
    ul.lst-kix_list_1-3 {
        list-style-type: none;
    }
    .lst-kix_list_3-5 > li:before {
        content: "\002022  ";
    }
    ul.lst-kix_list_1-4 {
        list-style-type: none;
    }
    ul.lst-kix_list_1-1 {
        list-style-type: none;
    }
    .lst-kix_list_3-4 > li:before {
        content: "\002022  ";
    }
    ul.lst-kix_list_1-2 {
        list-style-type: none;
    }
    ul.lst-kix_list_5-3 {
        list-style-type: none;
    }
    ul.lst-kix_list_1-7 {
        list-style-type: none;
    }
    .lst-kix_list_3-3 > li:before {
        content: "\002022  ";
    }
    ul.lst-kix_list_5-4 {
        list-style-type: none;
    }
    ul.lst-kix_list_1-8 {
        list-style-type: none;
    }
    ul.lst-kix_list_5-1 {
        list-style-type: none;
    }
    .lst-kix_list_8-0 > li:before {
        content: "" counter(lst-ctn-kix_list_8-0, decimal) ". ";
    }
    ul.lst-kix_list_1-5 {
        list-style-type: none;
    }
    ul.lst-kix_list_5-2 {
        list-style-type: none;
    }
    ul.lst-kix_list_1-6 {
        list-style-type: none;
    }
    .lst-kix_list_8-7 > li:before {
        content: "\002022  ";
    }
    .lst-kix_list_3-8 > li:before {
        content: "\002022  ";
    }
    .lst-kix_list_8-5 > li:before {
        content: "\002022  ";
    }
    .lst-kix_list_8-6 > li:before {
        content: "\002022  ";
    }
    .lst-kix_list_8-3 > li:before {
        content: "\002022  ";
    }
    .lst-kix_list_3-6 > li:before {
        content: "\002022  ";
    }
    .lst-kix_list_3-7 > li:before {
        content: "\002022  ";
    }
    .lst-kix_list_8-4 > li:before {
        content: "\002022  ";
    }
    ol.lst-kix_list_5-0.start {
        counter-reset: lst-ctn-kix_list_5-0 0;
    }
    .lst-kix_list_8-8 > li:before {
        content: "\002022  ";
    }
    .lst-kix_list_5-0 > li:before {
        content: "" counter(lst-ctn-kix_list_5-0, decimal) ") ";
    }
    ol.lst-kix_list_6-0 {
        list-style-type: none;
    }
    .lst-kix_list_4-8 > li:before {
        content: "\002022  ";
    }
    .lst-kix_list_5-3 > li:before {
        content: "\002022  ";
    }
    .lst-kix_list_4-7 > li:before {
        content: "\002022  ";
    }
    .lst-kix_list_5-2 > li:before {
        content: "\002022  ";
    }
    .lst-kix_list_5-1 > li:before {
        content: "\002022  ";
    }
    li.li-bullet-6:before {
        margin-left: -8.4pt;
        white-space: nowrap;
        display: inline-block;
        min-width: 8.4pt;
    }
    li.li-bullet-7:before {
        margin-left: -5.6pt;
        white-space: nowrap;
        display: inline-block;
        min-width: 5.6pt;
    }
    ul.lst-kix_list_4-8 {
        list-style-type: none;
    }
    .lst-kix_list_5-7 > li:before {
        content: "\002022  ";
    }
    ul.lst-kix_list_8-4 {
        list-style-type: none;
    }
    ul.lst-kix_list_8-5 {
        list-style-type: none;
    }
    ul.lst-kix_list_4-6 {
        list-style-type: none;
    }
    .lst-kix_list_5-6 > li:before {
        content: "\002022  ";
    }
    .lst-kix_list_5-8 > li:before {
        content: "\002022  ";
    }
    ul.lst-kix_list_8-2 {
        list-style-type: none;
    }
    ul.lst-kix_list_4-7 {
        list-style-type: none;
    }
    ul.lst-kix_list_8-3 {
        list-style-type: none;
    }
    ul.lst-kix_list_8-8 {
        list-style-type: none;
    }
    ul.lst-kix_list_8-6 {
        list-style-type: none;
    }
    ul.lst-kix_list_8-7 {
        list-style-type: none;
    }
    ul.lst-kix_list_4-0 {
        list-style-type: none;
    }
    ul.lst-kix_list_4-1 {
        list-style-type: none;
    }
    .lst-kix_list_5-4 > li:before {
        content: "\002022  ";
    }
    ul.lst-kix_list_4-4 {
        list-style-type: none;
    }
    .lst-kix_list_5-5 > li:before {
        content: "\002022  ";
    }
    ul.lst-kix_list_4-5 {
        list-style-type: none;
    }
    ul.lst-kix_list_8-1 {
        list-style-type: none;
    }
    ul.lst-kix_list_4-2 {
        list-style-type: none;
    }
    ul.lst-kix_list_4-3 {
        list-style-type: none;
    }
    ol.lst-kix_list_1-0.start {
        counter-reset: lst-ctn-kix_list_1-0 1;
    }
    .lst-kix_list_6-1 > li:before {
        content: "\002022  ";
    }
    .lst-kix_list_6-3 > li:before {
        content: "\002022  ";
    }
    .lst-kix_list_6-0 > li:before {
        content: "" counter(lst-ctn-kix_list_6-0, decimal) ") ";
    }
    .lst-kix_list_6-4 > li:before {
        content: "\002022  ";
    }
    li.li-bullet-2:before {
        margin-left: -8.2pt;
        white-space: nowrap;
        display: inline-block;
        min-width: 8.2pt;
    }
    .lst-kix_list_6-2 > li:before {
        content: "\002022  ";
    }
    .lst-kix_list_6-8 > li:before {
        content: "\002022  ";
    }
    ol.lst-kix_list_8-0.start {
        counter-reset: lst-ctn-kix_list_8-0 0;
    }
    .lst-kix_list_6-5 > li:before {
        content: "\002022  ";
    }
    .lst-kix_list_6-7 > li:before {
        content: "\002022  ";
    }
    .lst-kix_list_7-0 > li:before {
        content: "(" counter(lst-ctn-kix_list_7-0, decimal) ") ";
    }
    .lst-kix_list_6-6 > li:before {
        content: "\002022  ";
    }
    ol.lst-kix_list_5-0 {
        list-style-type: none;
    }
    .lst-kix_list_2-6 > li:before {
        content: "\002022  ";
    }
    .lst-kix_list_2-7 > li:before {
        content: "\002022  ";
    }
    .lst-kix_list_7-4 > li:before {
        content: "\002022  ";
    }
    .lst-kix_list_7-6 > li:before {
        content: "\002022  ";
    }
    ol.lst-kix_list_1-0 {
        list-style-type: none;
    }
    .lst-kix_list_2-4 > li:before {
        content: "\002022  ";
    }
    .lst-kix_list_2-5 > li:before {
        content: "\002022  ";
    }
    .lst-kix_list_2-8 > li:before {
        content: "\002022  ";
    }
    .lst-kix_list_7-1 > li:before {
        content: "\002022  ";
    }
    .lst-kix_list_7-5 > li:before {
        content: "\002022  ";
    }
    .lst-kix_list_7-2 > li:before {
        content: "\002022  ";
    }
    .lst-kix_list_7-3 > li:before {
        content: "\002022  ";
    }
    ul.lst-kix_list_7-5 {
        list-style-type: none;
    }
    ul.lst-kix_list_7-6 {
        list-style-type: none;
    }
    ul.lst-kix_list_7-3 {
        list-style-type: none;
    }
    ul.lst-kix_list_3-7 {
        list-style-type: none;
    }
    ul.lst-kix_list_7-4 {
        list-style-type: none;
    }
    ul.lst-kix_list_3-8 {
        list-style-type: none;
    }
    ul.lst-kix_list_7-7 {
        list-style-type: none;
    }
    ul.lst-kix_list_7-8 {
        list-style-type: none;
    }
    ul.lst-kix_list_3-1 {
        list-style-type: none;
    }
    ul.lst-kix_list_3-2 {
        list-style-type: none;
    }
    .lst-kix_list_7-8 > li:before {
        content: "\002022  ";
    }
    ul.lst-kix_list_3-0 {
        list-style-type: none;
    }
    ul.lst-kix_list_7-1 {
        list-style-type: none;
    }
    ul.lst-kix_list_3-5 {
        list-style-type: none;
    }
    ul.lst-kix_list_7-2 {
        list-style-type: none;
    }
    ul.lst-kix_list_3-6 {
        list-style-type: none;
    }
    ul.lst-kix_list_3-3 {
        list-style-type: none;
    }
    .lst-kix_list_7-7 > li:before {
        content: "\002022  ";
    }
    ul.lst-kix_list_3-4 {
        list-style-type: none;
    }
    li.li-bullet-1:before {
        margin-left: -6.3pt;
        white-space: nowrap;
        display: inline-block;
        min-width: 6.3pt;
    }
    .lst-kix_list_4-0 > li:before {
        content: "-  ";
    }
    .lst-kix_list_4-1 > li:before {
        content: "\002022  ";
    }
    li.li-bullet-3:before {
        margin-left: -4.2pt;
        white-space: nowrap;
        display: inline-block;
        min-width: 4.2pt;
    }
    .lst-kix_list_4-4 > li:before {
        content: "\002022  ";
    }
    .lst-kix_list_4-3 > li:before {
        content: "\002022  ";
    }
    .lst-kix_list_4-5 > li:before {
        content: "\002022  ";
    }
    .lst-kix_list_4-2 > li:before {
        content: "\002022  ";
    }
    .lst-kix_list_4-6 > li:before {
        content: "\002022  ";
    }
    ol.lst-kix_list_7-0.start {
        counter-reset: lst-ctn-kix_list_7-0 0;
    }
    li.li-bullet-8:before {
        margin-left: -5.6pt;
        white-space: nowrap;
        display: inline-block;
        min-width: 5.6pt;
    }
    li.li-bullet-9:before {
        margin-left: -5.6pt;
        white-space: nowrap;
        display: inline-block;
        min-width: 5.6pt;
    }
    ul.lst-kix_list_6-6 {
        list-style-type: none;
    }
    li.li-bullet-5:before {
        margin-left: -4.2pt;
        white-space: nowrap;
        display: inline-block;
        min-width: 4.2pt;
    }
    ul.lst-kix_list_6-7 {
        list-style-type: none;
    }
    ul.lst-kix_list_6-4 {
        list-style-type: none;
    }
    ul.lst-kix_list_2-8 {
        list-style-type: none;
    }
    ul.lst-kix_list_6-5 {
        list-style-type: none;
    }
    ul.lst-kix_list_6-8 {
        list-style-type: none;
    }
    ul.lst-kix_list_2-2 {
        list-style-type: none;
    }
    .lst-kix_list_1-0 > li:before {
        content: "(" counter(lst-ctn-kix_list_1-0, decimal) ") ";
    }
    ul.lst-kix_list_2-3 {
        list-style-type: none;
    }
    ul.lst-kix_list_2-0 {
        list-style-type: none;
    }
    ul.lst-kix_list_2-1 {
        list-style-type: none;
    }
    ul.lst-kix_list_6-2 {
        list-style-type: none;
    }
    ol.lst-kix_list_8-0 {
        list-style-type: none;
    }
    ul.lst-kix_list_2-6 {
        list-style-type: none;
    }
    ul.lst-kix_list_6-3 {
        list-style-type: none;
    }
    .lst-kix_list_1-1 > li:before {
        content: "-  ";
    }
    .lst-kix_list_1-2 > li:before {
        content: "\002022  ";
    }
    ul.lst-kix_list_2-7 {
        list-style-type: none;
    }
    ul.lst-kix_list_2-4 {
        list-style-type: none;
    }
    ul.lst-kix_list_6-1 {
        list-style-type: none;
    }
    ul.lst-kix_list_2-5 {
        list-style-type: none;
    }
    .lst-kix_list_1-3 > li:before {
        content: "\002022  ";
    }
    .lst-kix_list_1-4 > li:before {
        content: "\002022  ";
    }
    .lst-kix_list_1-0 > li {
        counter-increment: lst-ctn-kix_list_1-0;
    }
    .lst-kix_list_1-7 > li:before {
        content: "\002022  ";
    }
    .lst-kix_list_1-5 > li:before {
        content: "\002022  ";
    }
    .lst-kix_list_1-6 > li:before {
        content: "\002022  ";
    }
    li.li-bullet-0:before {
        margin-left: -12.5pt;
        white-space: nowrap;
        display: inline-block;
        min-width: 12.5pt;
    }
    li.li-bullet-4:before {
        margin-left: -3.7pt;
        white-space: nowrap;
        display: inline-block;
        min-width: 3.7pt;
    }
    .lst-kix_list_2-0 > li:before {
        content: "-  ";
    }
    .lst-kix_list_2-1 > li:before {
        content: "\002022  ";
    }
    ol.lst-kix_list_6-0.start {
        counter-reset: lst-ctn-kix_list_6-0 0;
    }
    .lst-kix_list_1-8 > li:before {
        content: "\002022  ";
    }
    .lst-kix_list_2-2 > li:before {
        content: "\002022  ";
    }
    .lst-kix_list_2-3 > li:before {
        content: "\002022  ";
    }
    ol {
        margin: 0;
        padding: 0;
    }
    table td,
    table th {
        padding: 0;
    }
    .c14 {
        border-right-style: solid;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        background-color: #fae3d4;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 99.2pt;
        border-top-color: #000000;
        border-bottom-style: solid;
    }
    .c25 {
        border-right-style: solid;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 304.9pt;
        border-top-color: #000000;
        border-bottom-style: solid;
    }
    .c10 {
        border-right-style: solid;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 157.3pt;
        border-top-color: #000000;
        border-bottom-style: solid;
    }
    .c46 {
        border-right-style: solid;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 56.7pt;
        border-top-color: #000000;
        border-bottom-style: solid;
    }
    .c17 {
        border-right-style: solid;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 99.2pt;
        border-top-color: #000000;
        border-bottom-style: solid;
    }
    .c4 {
        border-right-style: solid;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 196.8pt;
        border-top-color: #000000;
        border-bottom-style: solid;
    }
    .c29 {
        border-right-style: solid;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 106.3pt;
        border-top-color: #000000;
        border-bottom-style: solid;
    }
    .c34 {
        border-right-style: solid;
        border-bottom-color: #000000;
        border-top-width: 1pt;
        border-right-width: 1pt;
        border-left-color: #000000;
        vertical-align: top;
        border-right-color: #000000;
        border-left-width: 1pt;
        border-top-style: solid;
        border-left-style: solid;
        border-bottom-width: 1pt;
        width: 212.7pt;
        border-top-color: #000000;
        border-bottom-style: solid;
    }
    .c24 {
        margin-left: 5.3pt;
        padding-top: 0.5pt;
        list-style-position: inside;
        text-indent: 45pt;
        padding-bottom: 0pt;
        line-height: 1.1500000000000001;
        text-align: left;
        margin-right: 7.5pt;
    }
    .c23 {
        margin-left: 20.3pt;
        padding-top: 0.1pt;
        text-indent: 0.8pt;
        padding-bottom: 0pt;
        line-height: 1.1500000000000001;
        text-align: left;
        margin-right: 4.5pt;
        height: 11pt;
    }
    .c77 {
        margin-left: 8.2pt;
        padding-top: 7.8pt;
        text-indent: -0.5pt;
        padding-bottom: 0pt;
        line-height: 1.1500000000000001;
        text-align: center;
        margin-right: 7.2pt;
    }
    .c58 {
        margin-left: 29.8pt;
        padding-top: 0pt;
        text-indent: -7.8pt;
        padding-bottom: 0pt;
        line-height: 1.1500000000000001;
        text-align: left;
        margin-right: 9.7pt;
    }
    .c19 {
        margin-left: 6.2pt;
        padding-top: 3.6pt;
        padding-bottom: 0pt;
        line-height: 1.1500000000000001;
        text-align: left;
        margin-right: 6.2pt;
        height: 11pt;
    }
    .c9 {
        margin-left: 24.1pt;
        padding-top: 0pt;
        padding-left: -9.6pt;
        padding-bottom: 0pt;
        line-height: 1.1500000000000001;
        text-align: left;
        margin-right: 140.8pt;
    }
    .c7 {
        color: #000000;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 8pt;
        font-family: "Malgun Gothic";
        font-style: normal;
    }
    .c1 {
        color: #000000;
        font-weight: 700;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 8pt;
        font-family: "Malgun Gothic";
        font-style: normal;
    }
    .c80 {
        margin-left: 26.6pt;
        padding-top: 0.1pt;
        padding-left: -5.5pt;
        padding-bottom: 0pt;
        line-height: 1.1500000000000001;
        text-align: left;
        margin-right: 346.1pt;
    }
    .c6 {
        color: #0033cc;
        font-weight: 700;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 8pt;
        font-family: "Malgun Gothic";
        font-style: normal;
    }
    .c74 {
        margin-left: 20.3pt;
        padding-top: 0.2pt;
        text-indent: 0.1pt;
        padding-bottom: 0pt;
        line-height: 1.1500000000000001;
        text-align: left;
        margin-right: 15.8pt;
    }
    .c5 {
        color: #000000;
        font-weight: 700;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 12pt;
        font-family: "Malgun Gothic";
        font-style: normal;
    }
    .c44 {
        color: #000000;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 11pt;
        font-family: "Malgun Gothic";
        font-style: normal;
    }
    .c0 {
        color: #000000;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 12pt;
        font-family: "Malgun Gothic";
        font-style: normal;
        margin-top: 10px;
        display: flex;
    }
    .c8 {
        margin-left: 8.2pt;
        padding-top: 0pt;
        text-indent: -0.5pt;
        padding-bottom: 0pt;
        line-height: 1.1500000000000001;
        text-align: center;
        margin-right: 7.2pt;
    }
    .c47 {
        margin-left: 21.1pt;
        padding-top: 0pt;
        text-indent: 9.9pt;
        padding-bottom: 0pt;
        line-height: 1.1500000000000001;
        text-align: left;
        margin-right: 10.2pt;
    }
    .c54 {
        margin-left: 20.3pt;
        padding-top: 0.1pt;
        text-indent: 0.8pt;
        padding-bottom: 0pt;
        line-height: 1.1500000000000001;
        text-align: left;
        margin-right: 4.5pt;
    }
    .c71 {
        margin-left: 29.8pt;
        padding-top: 0pt;
        text-indent: -7.8pt;
        padding-bottom: 0pt;
        line-height: 1.1500000000000001;
        text-align: left;
        margin-right: 5.9pt;
    }
    .c64 {
        margin-left: 8.2pt;
        padding-top: 0.1pt;
        text-indent: -0.5pt;
        padding-bottom: 0pt;
        line-height: 1.1500000000000001;
        text-align: center;
        margin-right: 7.2pt;
    }
    .c32 {
        margin-left: 65.6pt;
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1.1500000000000001;
        text-align: center;
        margin-right: 65pt;
    }
    .c55 {
        margin-left: 78.2pt;
        padding-top: 0.1pt;
        padding-bottom: 0pt;
        line-height: 1.1500000000000001;
        text-align: center;
        margin-right: 77.9pt;
    }
    .c69 {
        margin-left: 19.8pt;
        padding-top: 0pt;
        text-indent: -10.8pt;
        padding-bottom: 0pt;
        line-height: 1.1500000000000001;
        text-align: left;
    }
    .c21 {
        margin-left: 28.6pt;
        padding-top: 0pt;
        padding-left: -11.7pt;
        padding-bottom: 0pt;
        line-height: 1.1500000000000001;
        text-align: left;
    }
    .c62 {
        margin-left: 18.9pt;
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1.1500000000000001;
        text-align: center;
        margin-right: 18.6pt;
    }
    .c70 {
        margin-left: 135.6pt;
        padding-top: 0.1pt;
        padding-bottom: 0pt;
        line-height: 1.1500000000000001;
        text-align: center;
        margin-right: 134.6pt;
    }
    .c45 {
        margin-left: 26.6pt;
        padding-top: 0pt;
        padding-left: -11.7pt;
        padding-bottom: 0pt;
        line-height: 1.1500000000000001;
        text-align: left;
    }
    .c79 {
        margin-left: 23.3pt;
        padding-top: 0pt;
        padding-left: -14.3pt;
        padding-bottom: 0pt;
        line-height: 1.1500000000000001;
        text-align: left;
    }
    .c65 {
        margin-left: 19.1pt;
        padding-top: 0.1pt;
        padding-bottom: 0pt;
        line-height: 1.1500000000000001;
        text-align: center;
        margin-right: 18.6pt;
    }
    .c66 {
        margin-left: 23.3pt;
        padding-top: 6.4pt;
        padding-left: -13.8pt;
        padding-bottom: 0pt;
        line-height: 1.1500000000000001;
        text-align: left;
    }
    .c59 {
        margin-left: 16.1pt;
        padding-top: 0.2pt;
        padding-bottom: 0pt;
        line-height: 1.1500000000000001;
        text-align: left;
        margin-right: 154.3pt;
    }
    .c83 {
        margin-left: 7pt;
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1.1500000000000001;
        text-align: left;
        margin-right: 3pt;
    }
    .c60 {
        margin-left: 91.2pt;
        padding-top: 0.1pt;
        padding-bottom: 0pt;
        line-height: 1.1500000000000001;
        text-align: center;
        margin-right: 90.8pt;
    }
    .c67 {
        margin-left: 23.3pt;
        padding-top: 0pt;
        padding-left: -13.8pt;
        padding-bottom: 0pt;
        line-height: 1.1500000000000001;
        text-align: left;
    }
    .c84 {
        margin-left: 6.2pt;
        padding-top: 3.6pt;
        padding-bottom: 0pt;
        line-height: 1.1500000000000001;
        text-align: left;
        margin-right: 6.2pt;
    }
    .c3 {
        margin-left: 65.6pt;
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1.1500000000000001;
        text-align: center;
        margin-right: 65.1pt;
    }
    .c81 {
        margin-left: 65.6pt;
        padding-top: 0.1pt;
        padding-bottom: 0pt;
        line-height: 1.1500000000000001;
        text-align: center;
        margin-right: 65.2pt;
    }
    .c26 {
        margin-left: 10px;
        padding-top: 0pt;
        padding-left: -12.4pt;
        padding-bottom: 0pt;
        line-height: 1.1500000000000001;
        text-align: left;
    }
    .c13 {
        margin-left: 19.1pt;
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1.1500000000000001;
        text-align: center;
        margin-right: 18.6pt;
    }
    .c68 {
        margin-left: 6.1pt;
        padding-top: 3.6pt;
        padding-bottom: 0pt;
        line-height: 1.1500000000000001;
        text-align: left;
        margin-right: 6.2pt;
    }
    .c42 {
        margin-left: 24.1pt;
        padding-top: 0pt;
        padding-left: -9.8pt;
        padding-bottom: 0pt;
        line-height: 1.1500000000000001;
        text-align: left;
    }
    .c82 {
        margin-left: 19.8pt;
        padding-top: 8pt;
        text-indent: -10.8pt;
        padding-bottom: 0pt;
        line-height: 1.1500000000000001;
        text-align: left;
    }
    .c72 {
        margin-left: 6.2pt;
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1.1500000000000001;
        text-align: left;
        margin-right: 15.8pt;
    }
    .c56 {
        margin-left: 23.3pt;
        padding-top: 6.3pt;
        padding-left: -13.8pt;
        padding-bottom: 0pt;
        line-height: 1.1500000000000001;
        text-align: left;
    }
    .c51 {
        margin-left: 7.2pt;
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1.1500000000000001;
        text-align: justify;
        margin-right: 4.1pt;
    }
    .c52 {
        margin-left: 19.8pt;
        padding-top: 0.1pt;
        text-indent: -10.8pt;
        padding-bottom: 0pt;
        line-height: 1.1500000000000001;
        text-align: left;
    }
    .c36 {
        margin-left: 20.3pt;
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1.1500000000000001;
        text-align: left;
    }
    .c18 {
        margin-left: 10.8pt;
        padding-top: 0.1pt;
        padding-bottom: 0pt;
        line-height: 1.1500000000000001;
        text-align: left;
    }
    .c16 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1.15;
        text-align: left;
        height: 11pt;
    }
    .c63 {
        color: #0033cc;
        text-decoration: none;
        vertical-align: baseline;
        font-family: "Malgun Gothic";
        font-style: normal;
        margin-top: 20px;
    }
    .c2 {
        padding-top: 1.9pt;
        padding-bottom: 0pt;
        line-height: 1.1500000000000001;
        text-align: left;
        margin-right: 258.7pt;
    }
    .c27 {
        padding-top: 0.1pt;
        padding-bottom: 0pt;
        line-height: 1.1500000000000001;
        text-align: left;
        height: 11pt;
    }
    .c61 {
        padding-top: 0.8pt;
        padding-bottom: 0pt;
        line-height: 1.1500000000000001;
        text-align: left;
        height: 11pt;
    }
    .c39 {
        margin-left: 16.1pt;
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1.1500000000000001;
        text-align: left;
    }
    .c43 {
        padding-top: 0.3pt;
        padding-bottom: 0pt;
        line-height: 1.1500000000000001;
        text-align: left;
        height: 11pt;
    }
    .c11 {
        margin-left: 5.3pt;
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1.1500000000000001;
        text-align: left;
    }
    .c49 {
        margin-left: 9.9pt;
        padding-top: 0.1pt;
        padding-bottom: 0pt;
        line-height: 1.1500000000000001;
        text-align: left;
    }
    .c28 {
        margin-left: 5.3pt;
        padding-top: 2.2pt;
        padding-bottom: 0pt;
        line-height: 1.1500000000000001;
        text-align: left;
    }
    .c22 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1.1500000000000001;
        text-align: left;
        height: 11pt;
    }
    .c37 {
        margin-left: 5.3pt;
        padding-top: 2.3pt;
        padding-bottom: 0pt;
        line-height: 1.1500000000000001;
        text-align: left;
    }
    .c50 {
        padding-top: 0.6pt;
        padding-bottom: 0pt;
        line-height: 1.1500000000000001;
        text-align: left;
        height: 11pt;
    }
    .c76 {
        margin-left: 5.3pt;
        padding-top: 2.4pt;
        padding-bottom: 0pt;
        line-height: 1.1500000000000001;
        text-align: left;
    }
    .c41 {
        margin-left: 6.4pt;
        border-spacing: 0;
        border-collapse: collapse;
        margin-right: auto;
        margin: 0;
    }
    .c15 {
        margin-left: 6.7pt;
        border-spacing: 0;
        border-collapse: collapse;
        margin-right: auto;
        margin: 0;
    }
    .c38 {
        text-decoration-skip-ink: none;
        font-size: 12pt;
        -webkit-text-decoration-skip: none;
        text-decoration: underline;
        display: flex;
        margin-bottom: 17px;
    }
    .c78 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1;
        text-align: left;
    }
    .c53 {
        background-color: #ffffff;
        max-width: 451.5pt;
        padding: 72pt 72pt 72pt 72pt;
    }
    .c30 {
        font-size: 12pt;
        font-weight: 700;
        margin-bottom: 10px;
        display: flex;
    }
    .c12 {
        padding: 0;
        margin: 0;
    }
    .c35 {
        height: 11.2pt;
    }
    .c33 {
        background-color: #fae3d4;
    }
    .c57 {
        height: 55.7pt;
    }
    .c20 {
        height: 15.4pt;
    }
    .c75 {
        height: 11pt;
    }
    .c48 {
        height: 15.6pt;
    }
    .c31 {
        height: 42.1pt;
    }
    .c73 {
        height: 11.3pt;
    }
    .c40 {
        font-weight: 700;
    }
    .agree_content .title {
        padding-top: 24pt;
        color: #000000;
        font-weight: 700;
        font-size: 36pt;
        padding-bottom: 6pt;
        font-family: "Malgun Gothic";
        line-height: 1;
        page-break-after: avoid;
        text-align: left;
    }
    .agree_content .subtitle {
        padding-top: 18pt;
        color: #666666;
        font-size: 24pt;
        padding-bottom: 4pt;
        font-family: "Georgia";
        line-height: 1;
        page-break-after: avoid;
        font-style: italic;
        text-align: left;
    }
    .agree_content li {
        color: #000000;
        font-size: 11pt;
        font-family: "Malgun Gothic";
    }
    div.agree_popup_wrapper div.agree_content * {
        max-width: 100%;
        line-height: 24px;
    }
    .agree_content p {
        margin: 0;
        color: #000000;
        font-size: 11pt;
        font-family: "Malgun Gothic";
    }
    .agree_content h1 {
        padding-top: 24pt;
        color: #000000;
        font-weight: 700;
        font-size: 24pt;
        padding-bottom: 6pt;
        font-family: "Malgun Gothic";
        line-height: 1;
        page-break-after: avoid;
        text-align: left;
    }
    .agree_content h2 {
        padding-top: 0pt;
        color: #000000;
        font-size: 8pt;
        padding-bottom: 0pt;
        font-family: "Malgun Gothic";
        line-height: 0.9458333333333333;
        text-align: left;
    }
    .agree_content h3 {
        padding-top: 0pt;
        color: #000000;
        font-weight: 700;
        font-size: 6pt;
        padding-bottom: 0pt;
        font-family: "Malgun Gothic";
        line-height: 1;
        text-align: left;
    }
    .agree_content h4 {
        padding-top: 12pt;
        color: #000000;
        font-weight: 700;
        font-size: 12pt;
        padding-bottom: 2pt;
        font-family: "Malgun Gothic";
        line-height: 1;
        page-break-after: avoid;
        text-align: left;
    }
    .agree_content h5 {
        padding-top: 11pt;
        color: #000000;
        font-weight: 700;
        font-size: 11pt;
        padding-bottom: 2pt;
        font-family: "Malgun Gothic";
        line-height: 1;
        page-break-after: avoid;
        text-align: left;
    }
    .agree_content h6 {
        padding-top: 10pt;
        color: #000000;
        font-weight: 700;
        font-size: 10pt;
        padding-bottom: 2pt;
        font-family: "Malgun Gothic";
        line-height: 1;
        page-break-after: avoid;
        text-align: left;
    }
}
