/* 2022-02-24 인수인계
로그인페이지 css입니다. */

div.login_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 328px;
    width: 100%;
}
div.login_wrapper img {
    margin-bottom: 45px;
}
div.login_wrapper div.login_input_wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    max-width: 328px;
    width: 100%;
}
div.login_wrapper div.login_input_wrapper input {
    position: static;
    max-width: 328px;
    width: 100%;
    height: 44px;
    left: 0px;
    top: 0px;
    background: #f2f2f2;
    border-radius: 8px 8px 0px 0px;

    /* Inside Auto Layout */
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 0px;
    padding-left: 1rem;
}
div.login_wrapper button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    position: static;
    width: 160px;
    height: 44px;
    left: 880px;
    top: 32px;
    background: #dddddd;
    border-radius: 1000px;

    /* Inside Auto Layout */
    margin: 8px 0px;
    color: #fff;
    font-size: 14px;
    margin-top: 40px;
}

div.login_wrapper button.active {
    background: #4587f5;
}

div.login_wrapper button:hover {
}
div.login_wrapper button:active {
}
div.login_wrapper button:focus {
}
div.login_wrapper ul {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
}
div.login_wrapper ul li {
    width: 90px;
    text-align: center;
    justify-content: center;
    display: flex;
    position: relative;
}
div.login_wrapper ul li a {
    font-size: 12px;
    color: #bbbbbb;
}
div.login_wrapper ul li a:hover {
}
div.login_wrapper ul li a:active {
}
div.login_wrapper ul li a:focus {
}

div.login_wrapper div.login_input_wrapper input:nth-of-type(2) {
    border-radius: 0 0 8px 8px;
    border-top: 1px solid #dddddd;
}
div.login_wrapper ul li:nth-of-type(2)::after {
    content: "";
    position: absolute;
    width: 1px;
    height: 12px;
    background: #dddddd;
    left: 0;
    top: calc(50% - 6px);
}

div.login_wrapper ul li:nth-of-type(2)::before {
    content: "";
    position: absolute;
    width: 1px;
    height: 12px;
    background: #dddddd;
    right: 0;
    top: calc(50% - 6px);
}

@media (max-width: 767px) {
    div.login_wrapper {
        margin-top: 8rem;
    }
}
