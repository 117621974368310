/* 2022-02-24 인수인계
팀워크 페이지 (/chat) css 입니다. */

#price {
    padding-left: 0;
}
.edit_member {
    display: none;
}
.chatting_header .right {
    position: absolute;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    right: 16px;
}
.chatting_header .right img {
    cursor: pointer;
}
.submit_modi {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    position: static;
    width: 160px;
    height: 44px;
    left: 80px;
    top: 32px;
    background: #4587f5;
    border-radius: 1000px;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 8px 0px;
    font-size: 14px;
    color: #fff;
    margin: 0 auto;
    margin-top: 2rem;
}
.teamwork_password {
    display: none;
}
.teamwork_password.active {
    display: block;
}
ul.member_list li.active {
    background: url(../images/icon_checked_active.svg) no-repeat !important;
    background-position: right !important;
}
ul.member_list li {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
    padding: 12px 0;
    background: url(../images/icon_check.svg) no-repeat !important;
    background-position: right !important;
    cursor: pointer;
}
ul.member_list p {
    width: 44px;
    height: 44px;
    border-radius: 100%;
    min-width: 44px;
    background: url(../images/icon_profile_basic.svg) no-repeat;
    min-height: 44px;
    background-size: cover !important;
    background-position: center !important;
    position: relative;
}
ul.member_list div {
    border-bottom: 1px solid #f2f2f2;
    padding: 12px 0;
    width: 100%;
}
ul.member_list div h1 {
    font-weight: 500;
    font-size: 14px;
}
ul.member_list div span {
    font-size: 12px;
    color: #bbbbbb;
}
.search_navi_wrapper {
    width: calc(100% - 380px);
    height: 62px;
    display: flex;
    align-items: center;
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 0 16px;
    justify-content: flex-end;
    background: #fff;
    left: 380px;
    z-index: 10;
    display: none;
}

.chat_search_popup_wrapper {
    display: flex;
    position: fixed;
    z-index: 102;
    left: 0;
    top: 0;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    display: none;
}

.chat_search_popup_wrapper {
}

.chat_search_popup_wrapper h1 {
    font-size: 16px;
    padding-bottom: 1rem;
    padding-top: 0.5rem;
}
.chat_search_popup_wrapper_background_black {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #00000050;
}

.chat_search_popup_wrapper .fc .fc-toolbar.fc-header-toolbar {
    padding: 0 10px;
}
.chat_search_popup_wrapper .fc {
    width: 100%;
    max-height: 400px;
}
.chat_search_popup_wrapper .tr {
    max-height: 40px;
}

.chat_search_popup_wrapper .fc .fc-toolbar-title {
    font-size: 18px;
}
.chat_search_popup_wrapper_box {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    position: relative;
    width: 380px;
    height: 80%;
    background: #ffffff;
    box-shadow: 0px 3px 12px rgb(0 0 0 / 20%);
    border-radius: 16px;
    z-index: 99;
    padding: 1rem;
    max-width: 95%;
}
.chat_search_popup_wrapper_box .fc-scrollgrid-section td > .fc-scroller-harness {
    min-height: 600px;
}

.chatting_area_show .search_input_wrapper img {
    /* margin-right: 10px; */
}
.search_navi_wrapper > div {
    width: 100%;
    display: flex;
    gap: 14px;
    align-items: center;
}
.search_navi_wrapper img {
    cursor: pointer;
}
.search_navi_wrapper img:nth-of-type(1) {
}
.search_navi_wrapper img:nth-of-type(2) {
}
.search_navi_wrapper span {
    font-size: 14px;
}
div.img_viewer div.slide_right,
div.img_viewer div.slide_left {
    display: none;
}
.d_10 .fc .fc-toolbar.fc-header-toolbar {
    padding: 0;
}
div.chatting_area div.chatting_header {
    min-height: 52px;
    display: flex;
    align-items: center;
    position: relative;
    justify-content: center;
    position: fixed;
    width: calc(100% - 380px);
    right: 0;
    top: 0;
    z-index: 8;
    background: #fff;
    padding: 0 16px;
}
#fc-dom-1 {
    display: none;
}
div.popup_wrapper_2 div.date_picker_box div.fullcal_wrapper {
    width: 100%;
    padding-top: 3rem;
}
.fc .fc-daygrid-day-number {
    font-size: 12px;
}
.fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events {
    min-height: 0;
    z-index: 1;
}
.fc .fc-scroller-harness {
    overflow: auto;
}
.fc .fc-toolbar.fc-header-toolbar {
    margin-bottom: 0;
}
.fc-direction-ltr .fc-button-group > .fc-button:not(:first-child) {
    margin-right: 0;
}
.fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events {
}
.fc .fc-daygrid-day-frame {
    display: flex;
    align-items: center;
    justify-content: center;
}
div.popup_wrapper_2 div.date_picker_box div.fullcal_wrapper {
    padding-top: 1rem;
}
.fc-daygrid-day-number {
    width: 44px;
    height: 44px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
/* 롤데이트피커 커스텀 */
.rolldate-container .rolldate-panel {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 273px;
    z-index: 1000;
    background: #fff;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    -webkit-transform: translate3d(0, 273px, 0);
    transform: translate3d(0, 273px, 0);
    max-width: 400px !important;
    left: calc(50% - 200px) !important;
    border-radius: 15px 15px 0 0;
    bottom: 0;
    transform: none;
}

.date_picker_box .fc .fc-toolbar.fc-header-toolbar {
    padding: 0 !important;
}
.fc .fc-toolbar.fc-header-toolbar {
    margin-bottom: 0;
    padding: 0 36px;
    margin-bottom: 30px;
}

@media (max-width: 1023px) {
    div.chatting_area div.chatting_header{width: calc(100% - 320px);}
}

@media (max-width: 768px) {
    .search_navi_wrapper {
        width: 100%;
        height: 62px;
        display: flex;
        align-items: center;
        position: fixed;
        bottom: 0;
        left: 0;
        padding: 0 16px;
        justify-content: flex-end;
        background: #fff;
        left: 0;
        z-index: 10;
        display: none;
    }

    div.chatting_area div.chatting_header {
        min-height: 52px;
        display: flex;
        align-items: center;
        position: relative;
        justify-content: center;
        position: fixed;
        width: calc(100% - 32px) !important;
        right: 16px;
        top: 0;
        z-index: 8;
        background: #fff;
    }
    div.top.search_module{
        padding-left: 0;
    }
    .chatting_header .right {
        right: 0;
    }
    .rolldate-container .rolldate-panel {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 273px;
        z-index: 1000;
        background: #fff;
        -webkit-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        -webkit-transform: translate3d(0, 273px, 0);
        transform: translate3d(0, 273px, 0);
        max-width: 400px !important;
        left: 0 !important;
        border-radius: 15px 15px 0 0;
        bottom: 0;
        transform: none;
        width: 100%;
    }
}
mark {
    background: orange;
    color: black;
}
.d_10 .fc-view-harness {
    height: 400px !important;
}
.d_10 div.cal_guide h1 {
    padding: 0;
    overflow: hidden;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2.5rem 0;
    white-space: nowrap;
}
.d_10 div.cal_guide h1 span{
    white-space: nowrap;
    min-width: 80px;
}
.d_10 div.cal_guide h1::before {
    margin-right: 10px;
    position: static;
    width: 100%;
}
.d_10 div.cal_guide h1::after {
    margin-left: 10px;
    position: static;
    width: 100%;
}

.fc .fc-toolbar-title {
    font-size: 24px;
    margin-left: 0;
}
.d_10 .fc .fc-view-harness {
    /* height: 420px !important; */
    height:auto !important
}
.d_10 .fc-direction-ltr .fc-button-group > .fc-button:not(:first-child) {
    margin-right: 0;
    padding-right: 0;
}
.d_10 .calendar {
    padding: 0 16px;
}
.d_10 .fc .fc-daygrid-day-number {
    font-size: 12px;
}
.d_10 .fc .fc-scrollgrid-section table {
    width: 100% !important;
    margin-right: 0;
}
.d_10 .fc .fc-daygrid-day-frame {
    position: relative;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}
.d_10 .fc .fc-daygrid-day-bottom{
    margin-bottom: 0;
    margin: 0;
    height: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.d_10 .fc .fc-daygrid-more-link{
    margin:0;
    font-size:10px
}
.d_10 .fc .fc-daygrid-body {
    width: 100% !important;
}
.d_10 .fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events {
    min-height: 1px;
}
.d_10 .fc .fc-scroller-harness {
    overflow: visible;
}
.d_10 .fc,
.d_10 .fc *,
.d_10 .fc *:before,
.d_10 .fc *:after {
    max-width: 100%;
}

.d_10 .calendar {
    margin-top: 1rem;
    min-height: 662px;
}
.d_10 div.cal_guide ul {
    padding-right: 0;
    padding: 0 16px;
}

.d_10 .data_wrapper {
    padding: 0 16px;
    padding-bottom: 2rem;
}
.d_10 .fc-daygrid-day.fc-day-today .fc-daygrid-day-number {
    width: 36px;
    height: 36px;
    min-width: 36px;
    min-height: 36px;
}
.d_10 div.cal_guide {
    /* margin-top: 4rem; */
}

.d_10 .fc-daygrid-day-number {
    width: 36px;
    height: 36px;
    min-width: 36px;
    min-height: 36px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.d_10 {
    overflow: scroll;
}
