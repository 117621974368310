/* 2022-02-24 인수인계
팀원추가등의 팝업 css입니다. */

.wrap {
    display: flex;
    min-height: 100vh;
    overflow: hidden;
    width: 100%;
    align-items: flex-start;
}
div.left_menu_bar {
    width: 60px;
    height: 100vh;
    background: #334455;
    display: flex;
    min-width: 60px;
    justify-content: center;
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    z-index: 9;
}
div.left_menu_bar ul {
    display: flex;
    flex-direction: column;
    align-items: center;
}
div.left_menu_bar ul li.user_profile {
    width: 44px;
    height: 44px;
    border-radius: 100%;
    background: url(../images/icon_profile_basic.svg) no-repeat;
    background-size: cover !important;
    background-position: center !important;
    cursor: pointer;
    background-color: #fff !important;
}
div.left_menu_bar ul li {
    margin: 15px 0;
    position: relative;
    width: 100%;
}

div.left_menu_bar ul li a {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

div.left_menu_bar ul li.member a {
    display: flex;
    align-items: center;
    justify-content: center;
}
div.left_menu_bar ul li img {
    filter: invert(53%) sepia(8%) saturate(0%) hue-rotate(131deg) brightness(98%) contrast(81%);
}
div.left_menu_bar ul li.active img {
    filter: none;
}
.chat_wrapper::-webkit-scrollbar { 
    display: none;
    width: 0 !important;
}
.chat_wrapper {
    max-width: 320px;
    width: 100%;
    height: 100%;
    background: #f7f7f7;
    border-right: 1px solid #dddddd;
    min-width: 320px;
    position: relative;
    position: fixed;
    left: 60px;
    z-index: 9;
    overflow-y: auto;
    -ms-overflow-style: none;
}
div.chat_header {
    width: 100%;
    padding: 0 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 52px;
}
div.chat_header h1 {
    font-size: 24px;
    font-weight: 500;
}
div.chat_header ul {
    display: flex;
    align-items: center;
    gap: 14px;
}

div.chat_header ul li a {
    display: flex;
    align-items: center;
    justify-content: center;
}

div.member_list div.my_profile {
    padding: 25px 16px;
    border-bottom: 1px solid #dddddd;
}
div.member_list div.my_profile > span {
    color: #bbb;
    font-size: 12px;
    font-weight: 300;
    margin: 10px 0;
    display: flex;
}
div.profile_row {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
    padding: 12px 0;
    /* cursor: pointer; */
    position: relative;
}
div.profile_row p.user_profile_img {
    width: 44px;
    height: 44px;
    border-radius: 100%;
    min-width: 44px;
    background: url(../images/icon_profile_basic.svg) no-repeat;
    min-height: 44px;
    background-size: cover !important;
    background-position: center !important;
    border: 1px solid #eaeaea;
    background-color: #ffffff !important;
}
div.profile_row .text_col{width: calc(100% - 44px - 12px);}
div.profile_row .memberListMsg{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    display: block;
    margin: 4px 0 0;
}

div.profile_row h1 {
    font-weight: 500;
    font-size: 14px;
}
div.profile_row span {
    font-size: 12px;
    color: #888888;
    font-weight: 300;
    word-break: break-all;
}
div.profile_row span span {
    cursor: pointer;
    margin-left: 4px;
}
div.member_list div.user_profile_wrapper {
    padding: 15px 16px;
}

div.member_list div.user_profile_wrapper div.user_profile_state h1 {
    color: #bbb;
    font-size: 12px;
    font-weight: 300;
    margin: 10px 0;
    display: flex;
    gap: 4px;
}

div.member_list div.user_profile_wrapper div.user_profile_state span:nth-of-type(2) {
    color: #4587f5;
}
.chat_icon_wrapper img {
    cursor: pointer;
}
div.member_list div.user_profile_wrapper div.profile_row {
    padding-bottom: 0;
    /* cursor: pointer; */
    min-height: 81px;
}

div.member_list div.user_profile_wrapper div.profile_row div.text_col {
    border-bottom: 1px solid #f2f2f2;
    padding: 12px 0;
}

div.chatting_area {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    padding: 0 16px;
    position: relative;
    padding-left: 380px;
    padding-bottom: 60px;
    padding-top: 4rem;
}
.chat_icon_wrapper {
    display: flex;
    gap: 13px;
    position: absolute;
    right: 0;
}
div.chatting_area div.chatting_header {
    min-height: 52px;
    display: flex;
    align-items: center;
    position: relative;
    justify-content: center;
    position: fixed;
    width: calc(100% - 16px - 380px);
    right: 16px;
    top: 0;
    z-index: 8;
    background: #fff;
}
div.chatting_area div.chatting_header .left {
    position: absolute;
    left: 16px;
}
div.chatting_area div.chatting_header div.left img {
    cursor: pointer;
}
div.chatting_area div.chat_data_area {
}
div.chatting_area div.chat_data_area div.line_notice {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1rem 0;
    position: relative;
}
div.chatting_area div.chat_data_area div.line_notice span {
    font-size: 12px;
    color: #bbbbbb;
    font-weight: 300;
    text-align: center;
    background: #fff;
    padding: 0 20px;
    position: relative;
    z-index: 5;
}
div.chatting_area div.chat_write {
    width: 100%;
    height: 62px;
    display: flex;
    align-items: center;
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 0 16px;
    justify-content: flex-end;
    background: #fff;
}
div.chatting_area div.chat_write,
div.chatting_area div.chat_write * {
    -webkit-user-select: auto !important;
}
div.chatting_area div.chat_write.active {
    bottom: 88px;
}
div.chatting_area div.chat_write.active .plus {
    background: url(../images/icon_close_type_1.svg) no-repeat !important;
}
.chat_data_inner video {
    display: block;
    width: 100%;
    max-width: 300px;
}
.videoBox{border-radius: 8px;overflow: hidden;border: 1px solid rgba(0,0,0,0.1);max-width: 300px;width: 100%;}
.videoHead{display: flex;justify-content: space-between;padding: 10px 12px;align-items: center;}
.videoName{font-size: 13px;width: calc(100% - 34px);text-overflow: ellipsis;white-space: nowrap;overflow: hidden;}
.videoHead>a{min-width: 24px;}

div.chatting_area div.chat_data_area div.line_notice::after {
    content: "";
    position: absolute;
    width: calc(100% - 16px);
    height: 1px;
    background: #dddddd;
    right: 0;
}

div.chatting_area div.chat_write ul.left_button_list {
    display: flex;
    align-items: center;
    flex-direction: row;
    height: 100%;
    gap: 11px;
    margin-right: 11px;
}
div.chatting_area div.chat_write ul.left_button_list li.add {
    height: 100%;
    background: url(../images/icon_add_paper.svg) no-repeat;
    background-position: center;
    min-width: 30px;
    cursor: pointer;
}
div.chatting_area div.chat_write ul.left_button_list li.plus {
    width: 100%;
    height: 100%;
    background: url(../images/icon_plus.svg) no-repeat;
    background-position: center !important;
    min-width: 30px;
    cursor: pointer;
}
div.chatting_area div.chat_write div.input_wrapper {
    width: 100%;
    min-width: 0;
    height: 36px;
    display: flex;
    align-items: center;
    background: #f2f2f2;
    border-radius: 10px;
}

div.write_input_wrapper {
    width: calc(100% - 460px);
    height: 36px;
    background: #f2f2f2;
    border-radius: 1000px;
    position: relative;
    display: flex;
    align-items: center;
}
div.write_input_wrapper textarea {
    width: calc(100% - 34px);
    padding-left: 16px;
    resize: none;
    display: flex;
    height: 100%;
    padding-top: 6.5px;
    font-size: 15px;
}
div.write_input_wrapper button.chat_submit {
    width: 30px;
    height: 30px;
    background: url(../images/icon_up.svg) no-repeat;
    background-color: #4587f5;
    border-radius: 100%;
    position: absolute;
    right: 3px;
    top: 3px;
    background-position: center;
    cursor: pointer;
    filter: grayscale(1);
    opacity: 0.5;
}
.open_search_module {
    position: absolute;
    right: 0;
    cursor: pointer;
    z-index: 9;
    right: 16px;
}
.open_info {
    position: absolute;
    right: 0;
    cursor: pointer;
    z-index: 9;
    right: 16px;
    min-width: 24px;
    height: 24px;
    border: 1px solid #4587f5;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
}
div.write_input_wrapper button.chat_submit.active {
    filter: grayscale(0);
    opacity: 1;
}
div.chat_data_inner {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-bottom: 5px;
    min-height: calc(100vh - 180px);
}
div.chat_data_inner div.chat_row {
    width: 100%;
    text-align: right;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: flex-end;
    gap: 8px;
}
div.chat_data_inner div.pointMark mark{
    background-color: yellow;
}

div.chat_data_inner div.chat_row .chat_row_wrapper_my:last-of-type .chat_info_right {
    display: flex;
    justify-content: flex-end;
}
.notMsgBox{
    background: #FF5555;
    border-radius: 16px;
    padding: 0 5px;
}
.btn_msgDel{
    padding: 10px;
}
.btn_msgRe{
    padding: 10px;
}
div.chat_data_inner div.chat_row .chat_row_wrapper_my > p {
    font-size: 16px;
    color: #fff;
    background-color: #4587f5;
    width: max-content;
    text-align: left;
    /* display: flex; */
    align-items: flex-start;
    justify-content: center;
    padding: 10px 15px;
    border-radius: 23px 23px 4px 23px;
    font-weight: 300;
    max-width: 480px;
    word-break: break-all;
    overflow: hidden;
    flex-direction: row;
}
div.chat_data_inner div.chat_row_notice .chat_row_wrapper_my > p {
    background-color: #fff;
    border: 1px solid #dddddd;
    border-radius: 23px 23px 23px 23px;
    color: #111111;
}
div.chat_list_wrapper {
}
div.chat_list_wrapper ul.chat_list {
    display: flex;
    width: 100%;
    padding-top: 2rem;
    flex-direction: column;
}
div.chat_list_wrapper ul.chat_list > li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0;
    width: calc(100% - 32px);
    overflow: hidden;
    margin: 0 auto;
    padding-bottom: 15px;
    border-bottom: 1px solid #f2f2f2;
    cursor: pointer;
    margin-top: 15px;
    position: relative;
    overflow: visible;
}
div.chat_list_wrapper ul.chat_list > li>a{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0;
    width: 100%;
}
div.chat_list_wrapper ul.chat_list li div.chat_list_col {
    display: flex;
    align-items: center;
    gap: 15px;
    width: calc(100% - 50px);
}
div.chat_list_wrapper ul.chat_list li span {
    font-size: 10px;
    color: #bbbbbb;
}
div.chat_list_wrapper ul.chat_list li p.chat_msg_count {
    font-size: 10px;
    background: #ff5555;
    border-radius: 100%;
    color: #fff;
    font-weight: 500;
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
}
div.chat_list_wrapper ul.chat_list li p.chat_img {
    min-width: 44px;
    min-height: 44px;
    width: 44px;
    height: 44px;
    background: #aaa;
    border-radius: 100%;
    background-position: center !important;
    background-size: cover !important;
    border: 1px solid #eaeaea;
    position: relative;
}
div.chat_list_wrapper ul.chat_list li div.chat_text_row {
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;
}
div.chat_list_wrapper ul.chat_list li div.chat_text_row div.team_work_title {
    display: flex;
    align-items: center;
    gap: 5px;
}
div.chat_list_wrapper ul.chat_list li div.chat_text_row div.chat_thumb_text {
    width: 100%;
}
div.chat_list_wrapper ul.chat_list li div.chat_text_row div.chat_thumb_text span {
    color: #888888;
    font-size: 12px;
    white-space: nowrap;
    width: 171px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
}
div.chat_list_wrapper ul.chat_list li div.chat_text_row h1 {
    font-weight: 500;
    font-size: 14px;
    word-break: break-all;
    height: 22px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: fit-content;
    max-width: 155px;
}
div.chat_list_wrapper ul.chat_list li div.chat_text_row > span {
    font-size: 12px;
}
div.chat_list_wrapper ul.chat_list li div.chat_text_row img {
}
div.chat_list_wrapper ul.chat_list li div.chat_text_row span {
    color: #888888;
    font-size: 14px;
    height: 22px;
}
.teamwork_list_l_p_menu {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 6px 12px;
    position: relative;
    width: 140px;
    height: auto;
    background: #ffffff;
    box-shadow: 0px 2px 8px rgb(0 0 0 / 10%);
    border-radius: 16px;
    position: absolute;
    right: 0px;
    top: 0rem;
    z-index: 99;
}
.sub_background_black {
    position: fixed;
    width: 100%;
    height: 100%;
    background: #ffffff50;
    z-index: 90;
    left: 0;
    top: 0;
}
.teamwork_list_l_p_menu li {
    font-size: 12px;
    height: 14px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    cursor: pointer;
    padding: 13px;
    border-radius: 4px;
    padding-left: 5px;
}
.teamwork_list_l_p_menu li.red {
}
div.chat_list_wrapper ul.chat_list li div.chat_list_col:nth-of-type(2) {
    flex-direction: column;
    min-width: 50px;
    gap: 2px;
    display: flex;
    align-items: flex-end;
}

div.data_wrapper.data_wrapper_m {
}
div.data_wrapper.data_wrapper_m > ul.data_list {
    margin-top: 3rem;
    padding: 0 16px;
}
div.data_wrapper.data_wrapper_m > ul.data_list > li {
}
div.data_wrapper.data_wrapper_m > ul.data_list > li > p.type_1 {
    min-width: 18px;
    min-height: 18px;
}
div.data_wrapper.data_wrapper_m > ul.data_list > li > div.data_list_textArea {
}
div.data_wrapper.data_wrapper_m > ul.data_list > li > div.data_list_textArea > h1 {
}
div.data_wrapper.data_wrapper_m > ul.data_list > li > div.data_list_textArea > span {
}
div.data_wrapper.data_wrapper_m > ul.data_list > li > div.data_list_textArea > h1 > span.date_created_at {
}
div.data_wrapper.data_wrapper_m > ul.data_list > li > div.data_list_textArea > h1 > span.data_price_detail {
}
div.data_wrapper.data_wrapper_m > ul.data_list > li > p.type_2 {
}
div.data_wrapper.data_wrapper_m > ul.data_list > li > p.type_3 {
    height: 18px;
}
div.data_wrapper.data_wrapper_m > ul.data_list > li > p.type_4 {
    height: 18px;
}

div.data_wrapper.data_wrapper_m > ul.data_list > li > p {
    min-width: 18px;
    min-height: 18px;
}

div.data_wrapper.data_wrapper_m > ul.data_list > li > p.type_5 {
    height: 18px;
}
div.chat_header_title_row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-top: 10px;
}
div.chat_header_title_row h1 {
    font-size: 24px;
}
div.chat_header_title_row button.add_cal {
    width: 12px;
    height: 12px;
    background: url(../images/icon_plus.svg) no-repeat;
    background-position: center;
}
div.chat_header_title_row button.add_cal:hover {
}
div.chat_header_title_row button.add_cal:active {
}
div.chat_header_title_row button.add_cal:focus {
}
div.chat_header_title_row_2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 1rem;
}
div.chat_header_title_row_2 h1 {
    color: #bbbbbb;
    font-size: 12px;
}
div.chat_header_title_row_2 button.next_sch {
    color: #bbbbbb;
    font-size: 12px;
    text-decoration: underline;
}
div.chat_header_title_row_2 button.next_sch:hover {
}
div.chat_header_title_row_2 button.next_sch:active {
}
div.chat_header_title_row_2 button.next_sch:focus {
}
div.cal_guide {
    width: 100%;
    padding: 0 20px;
}
div.cal_guide ul {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 12px;
    max-width: 700px;
    width: 100%;
    margin: 0 auto;
    justify-content: flex-end;
    padding-right: 3rem;
}
div.cal_guide ul li {
    display: flex;
    align-items: center;
    gap: 4px;
}
div.cal_guide ul li p {
    width: 5px;
    height: 5px;
    border-radius: 100%;
}
div.cal_guide ul li span {
    font-size: 10px;
    color: #bbbbbb;
}
div.cal_guide h1 {
    text-align: center;
    font-size: 12px;
    color: #bbbbbb;
    padding: 2rem 0;
    position: relative;
    width: 120px;
    margin: 0 auto;
    background: #fff;
}

div.cal_guide h1::after {
    content: "";
    width: 200%;
    height: 1px;
    background: #dddddd;
    position: absolute;
    right: 100%;
    top: calc(50% - 0.5px);
}

div.cal_guide h1::before {
    content: "";
    width: 200%;
    height: 1px;
    background: #dddddd;
    position: absolute;
    left: 100%;
    top: calc(50% - 0.5px);
}
div.cal_guide ul li:nth-of-type(1) p {
    background: #4587f5;
}
div.cal_guide ul li:nth-of-type(2) p {
    background: #00cc66;
}
div.cal_guide ul li:nth-of-type(3) p {
    background: #aabbcc;
}
div.cal_guide ul li:nth-of-type(4) p {
    background: #ffcc00;
}
div.cal_guide ul li:nth-of-type(5) p {
    background: #ff5555;
}
div.chat_wrapper.white_m_wrapper {
    background: #fff;
}

.right_popup {
    position: fixed;
    z-index: 15;
    right: 0;
    top: 0;
    left: inherit;
    border-left: 1px solid #eaeaea;
    display: none;
}
div.chat_wrapper.white_m_wrapper div.white_m_header {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 52px;
    position: relative;
}
div.chat_wrapper.white_m_wrapper div.white_m_header img {
    position: absolute;
    left: 16px;
    cursor: pointer;
    padding: 15px 0;
}
div.chat_wrapper.white_m_wrapper div.white_m_header span {
    font-size: 14px;
}
div.chat_wrapper.white_m_wrapper div.white_m_header button {
    font-size: 14px;
    color: #4587f5;
    position: absolute;
    right: 16px;
}
div.chat_wrapper.white_m_wrapper div.white_m_header button:hover {
}
div.chat_wrapper.white_m_wrapper div.white_m_header button:active {
}
div.chat_wrapper.white_m_wrapper div.white_m_header button:focus {
}
div.chat_wrapper.white_m_wrapper div.selected_member_wrapper {
    padding: 0 16px;
}
div.chat_wrapper.white_m_wrapper div.selected_member_wrapper h1 {
    color: #bbb;
    font-size: 12px;
    font-weight: 300;
    margin: 10px 0;
    display: flex;
    gap: 4px;
}
div.chat_wrapper.white_m_wrapper div.selected_member_wrapper h1 span.blue {
}
div.chat_wrapper.white_m_wrapper div.selected_member_wrapper ul.selected_member_list {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 17px;
    overflow: auto;
    padding: 1rem 0;
    border-bottom: 1px solid #bbbbbb;
}
div.chat_wrapper.white_m_wrapper div.selected_member_wrapper ul.selected_member_list li {
    display: flex;
    align-items: center;
    justify-content: center;
}
div.chat_wrapper.white_m_wrapper div.selected_member_wrapper ul.selected_member_list div.selected_profile_img {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 4px;
    position: relative;
}
div.chat_wrapper.white_m_wrapper div.selected_member_wrapper ul.selected_member_list div.selected_profile_img p.del_selected_memeber {
    width: 44px;
    height: 44px;
    border-radius: 100%;
    min-width: 44px;
    background: url(../images/icon_profile_basic.svg) no-repeat;
    min-height: 44px;
    background-size: cover !important;
    background-position: center !important;
}
div.chat_wrapper.white_m_wrapper div.selected_member_wrapper ul.selected_member_list div.selected_profile_img span {
    font-size: 10px;
}
div.chat_wrapper.white_m_wrapper div.all_memeber_list {
    padding: 0 16px;
}
div.chat_wrapper.white_m_wrapper div.all_memeber_list > h1 {
    color: #bbb;
    font-size: 12px;
    font-weight: 300;
    margin: 10px 0;
    display: flex;
    gap: 4px;
}
div.chat_wrapper.white_m_wrapper div.all_memeber_list h1 span {
    color: #4587f5;
}
div.chat_wrapper.white_m_wrapper div.all_memeber_list ul.member_list {
}
div.chat_wrapper.white_m_wrapper div.all_memeber_list ul.member_list li.active {
    background: url(../images/icon_checked_active.svg) no-repeat !important;
    background-position: right !important;
}
div.chat_wrapper.white_m_wrapper div.all_memeber_list ul.member_list li {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
    padding: 12px 0;
    background: url(../images/icon_check.svg) no-repeat !important;
    background-position: right !important;
    cursor: pointer;
}
div.chat_wrapper.white_m_wrapper div.all_memeber_list_2 ul.member_list li {
    background: none !important;
}
div.chat_wrapper.white_m_wrapper div.all_memeber_list ul.member_list p {
}
div.chat_wrapper.white_m_wrapper div.all_memeber_list ul.member_list div {
}
div.chat_wrapper.white_m_wrapper div.all_memeber_list ul.member_list div h1 {
}
div.chat_wrapper.white_m_wrapper div.all_memeber_list ul.member_list div span {
}
div.chat_wrapper.white_m_wrapper div.all_memeber_list ul.member_list p {
    width: 44px;
    height: 44px;
    border-radius: 100%;
    min-width: 44px;
    background: url(../images/icon_profile_basic.svg) no-repeat;
    min-height: 44px;
    background-size: cover !important;
    background-position: center !important;
    position: relative;
    background-color: #fff !important;
    border: 1px solid #eaeaea;
}
div.chat_wrapper.white_m_wrapper div.all_memeber_list ul.member_list div {
    border-bottom: 1px solid #f2f2f2;
    padding: 12px 0;
    width: 100%;
}
div.chat_wrapper.white_m_wrapper div.all_memeber_list ul.member_list div h1 {
    font-weight: 500;
    font-size: 14px;
}
div.chat_wrapper.white_m_wrapper div.all_memeber_list ul.member_list div span {
    font-size: 12px;
    color: #bbbbbb;
}

.remove_sel_button {
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 100%;
    background: url(../images/icon_close_s_w.svg) no-repeat;
    background-color: #aaa;
    background-position: center;
    right: 0;
    top: 0;
    cursor: pointer;
}

div.member_add_module {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99;
    display: none;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    height: 100%;
}
div.member_add_module div.member_add_module_box {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    position: relative;
    width: 330px;
    background: #ffffff;
    box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.2);
    border-radius: 16px;
    padding-bottom: 2rem;
    z-index: 6;
    max-width: 90%;
    display: none;
}
div.member_add_module div.member_add_module_box h1 {
    font-size: 16px;
    font-weight: 500;
    padding: 23px 0;
}
div.member_add_module div.member_add_module_box ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 39px;
}
div.member_add_module div.member_add_module_box ul li {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    cursor: pointer;
}
div.member_add_module div.member_add_module_box ul p {
    width: 44px;
    height: 44px;
    border-radius: 100%;
}
div.member_add_module div.member_add_module_box ul span {
    font-size: 10px;
}

div.member_add_module div.member_add_module_box ul li:nth-of-type(1) p {
    background: url(../images/icon_add_1.svg) no-repeat;
    background-position: center;
}
div.member_add_module div.member_add_module_box ul li:nth-of-type(2) p {
    background: url(../images/icon_add_2.svg) no-repeat;
    background-position: center;
}
div.member_add_module div.member_add_module_box ul li:nth-of-type(3) p {
    background: url(../images/icon_add_3.svg) no-repeat;
    background-position: center;
}

.background_black {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #00000050;
}
div.add_for_number_box {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    position: relative;
    background: #ffffff;
    box-shadow: 0px 3px 12px rgb(0 0 0 / 20%);
    border-radius: 16px;
    padding-bottom: 2rem;
    z-index: 6;
    width: 330px;
    display: none;
    max-width: 90%;
}
div.add_for_number_box h1 {
    font-size: 16px;
    font-weight: 500;
    padding: 23px 0;
}
div.add_for_number_box input#add_phone_number {
    height: 44px;
    background: #f2f2f2;
    border-radius: 8px;
    padding-left: 1rem;
    width: calc(100% - 30px);
    margin-bottom: 1.5rem;
}
div.add_for_number_box ul.popup_button_wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    padding-right: 1rem;
}
div.add_for_number_box ul.popup_button_wrapper li {
}
div.add_for_number_box ul.popup_button_wrapper li button {
    font-size: 14px;
    cursor: pointer;
    width: 62px;
    text-align: center;
}
div.add_for_number_box ul.popup_button_wrapper li:nth-of-type(2) button {
    font-size: 14px;
    color: #4587f5;
}
div.add_for_number_box ul.popup_button_wrapper li button:hover {
}
div.add_for_number_box ul.popup_button_wrapper li button:active {
}
div.add_for_number_box ul.popup_button_wrapper li button:focus {
}

div.add_for_number_result_box {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    position: relative;
    width: 330px;
    background: #ffffff;
    box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.2);
    border-radius: 16px;
    z-index: 6;
    padding-bottom: 2rem;
    display: none;
}
div.add_for_number_result_box div.profile {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    flex-direction: column;
    margin-bottom: 2rem;
    margin-top: 2rem;
}
div.add_for_number_result_box div.profile p.profile_img {
    width: 68px;
    height: 68px;
    border-radius: 100%;
    background-position: center !important;
    background-size: cover !important;
}
div.add_for_number_result_box div.profile h1 {
    font-size: 14px;
    text-align: center;
}
div.add_for_number_result_box ul.popup_button_wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    padding-right: 1rem;
}
div.add_for_number_result_box ul.popup_button_wrapper li {
}
div.add_for_number_result_box ul.popup_button_wrapper li button {
    font-size: 14px;
    cursor: pointer;
    width: 62px;
    text-align: center;
}
div.add_for_number_result_box ul.popup_button_wrapper li button:hover {
}
div.add_for_number_result_box ul.popup_button_wrapper li button:active {
}
div.add_for_number_result_box ul.popup_button_wrapper li button:focus {
}

div.add_for_number_result_box ul.popup_button_wrapper li:nth-of-type(2) button {
    font-size: 14px;
    color: #4587f5;
}
div.add_me_wrapper_box {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    position: relative;
    width: 330px;
    background: #ffffff;
    box-shadow: 0px 3px 12px rgb(0 0 0 / 20%);
    border-radius: 16px;
    padding-bottom: 2rem;
    z-index: 6;
    display: none;
}
div.add_me_wrapper_box h1 {
    font-size: 16px;
    font-weight: 500;
    padding: 23px 0;
}
div.add_me_wrapper_box ul:nth-of-type(1) {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 0 20px;
    margin-bottom: 2rem;
    max-height: 300px;
    overflow-y: scroll;
    overflow-x: hidden;
}
div.add_me_wrapper_box ul li.active {
    background: url(../images/icon_checked_active.svg) no-repeat !important;
    background-position: right !important;
}
div.add_me_wrapper_box ul li.active p {
}
div.add_me_wrapper_box ul li.active span {
}
div.add_me_wrapper_box > ul:nth-of-type(1) li {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
    height: 60px;
    border-bottom: 1px solid #f2f2f2;
    background: url(../images/icon_check.svg) no-repeat;
    background-position: right;
    cursor: pointer;
    min-height: 60px;
}
div.add_me_wrapper_box ul li p {
    width: 36px;
    height: 36px;
    border-radius: 100%;
    background-position: center !important;
    background-size: cover !important;
    background: #aaa;
}
div.add_me_wrapper_box ul li span {
    font-size: 14px;
}

div.add_me_wrapper_box ul li.active span {
    color: #4587f5;
}

div.add_me_wrapper_box ul li .added_itemNameBox{
    max-width: calc(100% - 71px);
}
div.add_me_wrapper_box ul li .added_itemNameBox b{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    display: block;
    font-size: 12px;
    color: #888888;
    font-weight: 300;
    word-break: break-all;
    font-family: "noto_sans", "sans-serif";
}

div.add_me_wrapper_box ul.popup_button_wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    padding-right: 1rem;
}
div.add_me_wrapper_box ul.popup_button_wrapper li {
}
div.add_me_wrapper_box ul.popup_button_wrapper li button {
    font-size: 14px;
    cursor: pointer;
    width: 62px;
    text-align: center;
}
div.add_me_wrapper_box ul.popup_button_wrapper li button:hover {
}
div.add_me_wrapper_box ul.popup_button_wrapper li button:active {
}
div.add_me_wrapper_box ul.popup_button_wrapper li button:focus {
}

div.add_me_wrapper_box ul.popup_button_wrapper li:nth-of-type(2) button {
    color: #4587f5;
}

div.add_for_id_box {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    position: relative;
    background: #ffffff;
    box-shadow: 0px 3px 12px rgb(0 0 0 / 20%);
    border-radius: 16px;
    padding-bottom: 2rem;
    z-index: 6;
    width: 330px;
    display: none;
    max-width: 90%;
}
div.add_for_id_box h1 {
    font-size: 16px;
    font-weight: 500;
    padding: 23px 0;
}
div.add_for_id_box input#add_id_number {
    height: 44px;
    background: #f2f2f2;
    border-radius: 8px;
    padding-left: 1rem;
    width: calc(100% - 30px);
    margin-bottom: 1.5rem;
}
div.add_for_id_box ul.popup_button_wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    padding-right: 1rem;
}
div.add_for_id_box ul.popup_button_wrapper li {
}
div.add_for_id_box ul.popup_button_wrapper li button {
    font-size: 14px;
    cursor: pointer;
    width: 62px;
    text-align: center;
}
div.add_for_id_box ul.popup_button_wrapper li button:hover {
}
div.add_for_id_box ul.popup_button_wrapper li button:active {
}
div.add_for_id_box ul.popup_button_wrapper li button:focus {
}

div.add_for_id_box ul.popup_button_wrapper li:nth-of-type(2) button {
    color: #4587f5;
}

.memeber_none {
    font-size: 13px;
    color: #c5c5c5;
    margin-bottom: 3rem;
    display: none;
}

div.report_popup_wrapper {
    display: flex;
    flex-direction: column;
    padding: 0px;
    position: relative;
    width: 330px;
    background: #ffffff;
    box-shadow: 0px 3px 12px rgb(0 0 0 / 20%);
    border-radius: 16px;
    z-index: 6;
    padding: 0 24px;
    display: none;
}
div.report_popup_wrapper > h1 {
    font-size: 16px;
    height: 68px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
}
div.report_popup_wrapper ul.report_type_list {
}
div.report_popup_wrapper ul.report_type_list li {
}
div.report_popup_wrapper ul.report_type_list li label {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 7px 0;
}
div.report_popup_wrapper ul.report_type_list li input {
    border: 1px solid #dddddd;
    width: 28px;
    height: 28px;
    border-radius: 100%;
}
div.report_popup_wrapper ul.report_type_list li span {
    font-size: 14px;
}
div.report_popup_wrapper div.file_add_area {
    margin-top: 1rem;
}
div.report_popup_wrapper div.file_add_area h1 {
    font-size: 12px;
    margin: 1rem 0;
}
div.report_popup_wrapper div.file_add_area ul.b_submit_file_list {
}
div.report_popup_wrapper div.file_add_area ul.b_submit_file_list li.added_file {
    padding-right: 8px;
}
div.report_popup_wrapper div.file_add_area ul.b_submit_file_list li.file_add_button {
}
div.report_popup_wrapper div.file_add_area ul.b_submit_file_list p.file_del_button {
}
div.report_popup_wrapper div.file_add_area ul.b_submit_file_list div.file_icon {
}
div.report_popup_wrapper div.file_add_area ul.b_submit_file_list span.file_name {
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    height: 16px;
}
div.report_popup_wrapper div.file_add_area ul.b_submit_file_list span.file_size {
}
div.report_popup_wrapper div.file_add_area ul.b_submit_file_list label {
}
div.report_popup_wrapper div.file_add_area ul.b_submit_file_list label input {
}
.report_popup_wrapper_button_list {
    height: 72px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 31px;
}
.report_popup_wrapper_button_list li {
    font-size: 14px;
    cursor: pointer;
}
.report_popup_wrapper_button_list li:nth-of-type(1) {
    color: #888888;
}
.report_popup_wrapper_button_list li:nth-of-type(2) {
}

div.report_popup_wrapper ul.report_type_list li input:checked {
    background: url(../images/icon_checked.svg) no-repeat;
    background-size: cover;
}
div.memeber_list_popup {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 11;
    width: 100%;
    height: 100%;
    display: none;
}
div.memeber_list_popup div.member_list_box {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    position: relative;
    width: 330px;
    height: auto;
    background: #ffffff;
    box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.2);
    border-radius: 16px;
    padding: 1rem 0;
    z-index: 8;
}
div.memeber_list_popup div.member_list_box > h1 {
    font-size: 16px;
    padding: 1rem 0;
}
div.memeber_list_popup div.member_list_box ul.member_list {
    width: 100%;
    padding: 0 20px;
    max-height: 300px;
    overflow-y: auto;
}
div.memeber_list_popup div.member_list_box ul.member_list li {
}
div.memeber_list_popup div.member_list_box ul.member_list p.new_profile_item.master {
}
div.memeber_list_popup div.member_list_box ul.member_list div {
}
div.memeber_list_popup div.member_list_box ul.member_list div h1 {
}
div.memeber_list_popup div.member_list_box ul:nth-of-type(2) {
    display: flex;
    align-items: center;
    gap: 20px;
    width: 100%;
    justify-content: flex-end;
    padding-right: 16px;
    padding: 10px 16px;
}
div.memeber_list_popup div.member_list_box ul li {
    font-size: 14px;
    cursor: pointer;
}
div.memeber_list_popup div.member_list_box ul li.red {
}
div.sub_popup_wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 6px 12px;
    position: relative;
    width: 140px;
    height: 68px;
    background: #ffffff;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    position: absolute;
    right: 0;
    top: 3rem;
    display: none;
}
div.sub_popup_wrapper ul {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: center;
    gap: 7px;
}
div.sub_popup_wrapper ul li {
    font-size: 13px;
    cursor: pointer;
}
div.sub_popup_wrapper ul li.red {
}
div.chat_row_2 {
    padding-left: 12px;
    display: flex;
    gap: 12px;
    position: relative;
    max-width: calc(100% - 45px);
}
div.chat_row_2 div.chat_profile {
    width: 32px;
    height: 32px;
    background: #aaa;
    border-radius: 100%;
    background-position: center !important;
    background-size: cover !important;
    border: 1px solid #eaeaea;
    min-width: 32px;
    min-height: 32px;
    cursor: pointer;
}

div.chat_row_2 div.chat_profile.chatLeader_profile{
    position: relative;
}

div.chat_row_2 div.chat_profile.chatLeader_profile::before{
    content: "";
    position: absolute;
    width: 12px;
    height: 12px;
    border-radius: 100%;
    background: url(../images/icon_master_1.svg) no-repeat;
    background-position: center;
    background-size: cover;
    right: -2px;
    bottom: 0px;
}

div.chat_row_2 div.chat_profile.chatSubLeader_profile{
    position: relative;
}

div.chat_row_2 div.chat_profile.chatSubLeader_profile::before{
    content: "";
    position: absolute;
    width: 12px;
    height: 12px;
    border-radius: 100%;
    background: url(../images/icon_master_2.svg) no-repeat;
    background-position: center;
    background-size: cover;
    right: -2px;
    bottom: 0px;
}

div.chat_row_2 div.chat_info {
    width: 100%;
}
div.chat_row_2 div.chat_info div.chat_info_left {
}
div.chat_row_2 div.chat_info div.chat_info_left h1.chat_name {
    font-size: 12px;
    color: #888888;
    margin-bottom: 5px;
}
div.chat_row_2 div.chat_info div.chat_info_left div.text_chat_wrapper {
    display: flex;
    flex-direction: column;
    gap: 6px;
}
.disabled_chat div.chat_list_col:nth-of-type(1) > p::after {
    content: "";
    position: absolute;
    width: 16px;
    height: 16px;
    background: url("../images/icon_disabled.svg");
    right: 0px;
    bottom: -4px;
    background-position: center;
    background-size: cover;
}
div.chat_row_2 div.chat_info div.chat_info_left > div > div > p {
    font-size: 16px;
    color: #111111;
    background-color: #f2f2f2;
    width: max-content;
    /* display: flex; */
    align-items: center;
    justify-content: center;
    padding: 10px 15px;
    border-radius: 23px 23px 23px 23px;
    font-weight: 300;
    max-width: 480px;

    flex-direction: row;

    display: block;
    word-break: break-word;
}
div.chat_row_2 div.chat_info div.chat_info_left > div > div:nth-of-type(1) > p span {
    cursor: pointer;
    display: block;
    margin: 6px 0 0;
    padding: 0;
    text-align: left;
    width: 100%;
}
div.chat_row_2.chat_row_notice div.chat_info div.chat_info_left > div > div > p {
    font-size: 16px;
    color: #111111;
    background-color: #ffffff;
    width: max-content;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 15px;
    border-radius: 23px 23px 23px 23px !important;
    font-weight: 300;
    max-width: 480px;
    border: 1px solid #dddddd;
}
div.chat_row_2 div.chat_info div.chat_info_left > div > div:nth-of-type(1) > p {
    border-radius: 23px 23px 23px 4px;
}
div.chat_row_2 div.chat_info div.chat_info_right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    display: none;
}

div.text_chat_wrapper div.text_chat_inner_row:last-of-type div.chat_info_right {
    display: flex !important;
    justify-content: flex-end;
}
.chat_row_img_2 div.text_chat_inner_row:last-of-type div.chat_info_right {
    display: flex !important;
    justify-content: flex-end;
}
div.chat_row_2 div.chat_info div.chat_info_left > div > div:nth-of-type(2) > p {
    border-radius: 4px 23px 23px 23px;
}

div.chat_row_2 div.chat_info div.chat_info_right span.un_read {
    font-size: 12px;
    color: #4587f5;
}
div.chat_row_2 div.chat_info div.chat_info_right p.time {
    color: #bbbbbb;
    font-size: 12px;
}
div.text_chat_inner_row {
    display: flex;
    gap: 6px;
}
div.text_chat_inner_row p {
}
div.text_chat_inner_row div.chat_info_right {
}
div.text_chat_inner_row div.chat_info_right span.un_read {
}
div.text_chat_inner_row div.chat_info_right p.time {
}
div.chat_row {
}
div.chat_row div.chat_row_wrapper_my {
    display: flex;
    flex-flow: row-reverse;
    gap: 6px;
    width: 100%;
    padding-left: 20px;
}
div.chat_row div.chat_row_wrapper_my p {
}
div.chat_row div.chat_row_wrapper_my div.chat_info_right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    display: none;
}
div.chat_row div.chat_row_wrapper_my div.chat_info_right span.un_read {
    font-size: 12px;
    color: #4587f5;
}
div.chat_row div.chat_row_wrapper_my div.chat_info_right p.time {
    color: #bbbbbb;
    font-size: 12px;
}
.rest_chat_data_button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 16px);
    border-radius: 8px;
    background: #f4f4f4;
    padding: 0 1rem;
    margin: 0 auto;
    height: 50px;
    margin-left: 16px;
    margin-bottom: 1rem;
    display: none;
    min-height: 50px;
    position: fixed;
    top: 0;
    left: 0;
    transform: translate(-9999px,-9999px);
}
.rest_chat_data_button h1 {
    font-size: 14px;
}
.rest_chat_data_button button {
    cursor: pointer;
    font-size: 14px;
    color: #4587f5;
    font-weight: 500;
}

div.response_prev_ui {
    width: calc(100% - 380px);
    position: fixed;
    background: #ffffff;
    box-shadow: 0px -2px 2px rgb(0 0 0 / 5%);
    bottom: 62px;
    border-bottom: 1px solid #f2f2f2;
    display: flex;
    flex-direction: column;
    padding: 12px 20px;
    gap: 3px;
    overflow: hidden;
    justify-content: center;
}
div.response_prev_ui h1 {
    font-size: 14px;
}
div.response_prev_ui span {
    font-size: 14px;
    color: #dddddd;
}
div.response_prev_ui img {
    position: absolute;
    right: 25px;
    cursor: pointer;
}

div.chat_long_menu {
    display: none;
    position: absolute;
    z-index: 9;
}
div.chat_long_menu div.chat_long_menu_white {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 100;
    background: #ffffff30;
}
.copy_target {
    width: 1px;
    height: 1px;
    opacity: 0;
}
div.chat_long_menu ul li {
    align-items: center;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    font-size: 12px;
    height: 14px;
    justify-content: flex-start;
    width: 100%;
    height: 25px;
    align-items: center;
    width: 100%;
}

div.chat_long_menu ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 6px 12px;
    position: relative;
    width: 140px;
    height: auto;
    background: #ffffff;
    box-shadow: 0px 2px 8px rgb(0 0 0 / 10%);
    border-radius: 16px;
    position: absolute;
    top: 0rem;
    z-index: 101;
    left: 80px;
    top: 40px;
}
div.chat_long_menu ul li.chat_long_menu_copy {
}
div.chat_long_menu ul li.chat_resend {
}
div.response_msg_wrapper {
}

.morePage .chat_wrapper {
    z-index: 99;
}

div.status_select_popup {
    z-index: 99;
}
div.response_msg_wrapper div.response_msg_wrapper_top {
}
div.response_msg_wrapper div.response_msg_wrapper_top h1 {
}
div.response_msg_wrapper div.response_msg_wrapper_top span {
    text-overflow: ellipsis;
    overflow: hidden;
    width: auto;
    display: block;
}
div.response_msg_wrapper p {
}
@media (max-width: 1023px) {
    div.write_input_wrapper{
        width: calc(100% - 400px);
    }
    div.chatting_area div.chatting_header{
        width: calc(100% - 320px);
    }
    div.chatting_area{
        padding-left: 320px;
    }
    div.pc_right_area{
        padding-left: 320px;
    }
    .pc_right_area_top+div:not(.setting_gray_wrapper){
        padding: 0 20px;
    }
    .pc_right_area_top+div+.data_wrapper{
        padding:0 20px
    }
    .view_profile_module+.chat_wrapper,.left_menu_bar+.chat_wrapper{
        left: 0;
        padding-bottom: calc(6rem + 60px);
    }
    div.data_wrapper > div.data_select_row > div.send_data>select{
        min-width: 82px;
    }
    div.left_menu_bar {
        max-width: 320px;
        height: 60px;
        width: 100%;
        position: fixed;
        left: 0;
        bottom: 0;
        top: inherit;
        z-index: 100;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        background: #fff;
        border-top: 1px solid #00000010;
        border-right: 1px solid #00000010;
    }

    div.left_menu_bar.disabled {
        z-index: 6;
    }
    div.left_menu_bar ul {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
    }
    div.left_menu_bar ul li.user_profile {
        display: none;
    }
    div.left_menu_bar ul li a {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 30px;
        margin: 0 auto;
    }

    div.data_save_area_1 > h1 {
        font-weight: 300;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        height: 92px;
        font-size: 16px;
        flex-direction: column;
        justify-content: flex-start;
        align-items: baseline;
    }
    div.data_save_area_1 {
        padding: 0 16px;
        margin-top: 1rem;
    }
    div.data_save_area_1 h1 span {
        font-size: 16px;
    }
    div.data_save_area_1 ul li span {
        font-size: 14px;
    }
    div.data_save_area_1 ul li {
        height: 45px;
    }
    div.data_save_area_1 ul li {
        padding-left: 0.875rem;
    }
    div.data_save_area_1 div.text_box_big h1 {
        height: 52px;
        font-size: 20px;
        padding-right: 1rem;
    }
    div.data_save_area_1 div.text_box_big h1 span {
        font-size: 20px;
    }
    div.response_prev_ui h1,
    div.response_prev_ui span {
        font-size: 12px;
    }
}
@media (max-width: 768px) {
    div.pc_right_area{
        padding-left: 0;
        padding-right: 0;
    }
    .open_search_module {
        /* right: 0; */
    }
    div.response_prev_ui {
        width: 100%;
        position: fixed;
        background: #ffffff;
        box-shadow: 0px -2px 2px rgb(0 0 0 / 5%);
        bottom: 62px;
        border-bottom: 1px solid #f2f2f2;
        display: flex;
        flex-direction: column;
        padding: 12px 20px;
        gap: 3px;
        overflow: hidden;
        justify-content: center;
        left: 0;
    }
    div.more_b_info_wrapper ul li span {
        font-size: 12px;
    }
    div.more_b_info_wrapper ul li {
        height: 33px;
    }
    div.pc_right_area div.right_area_my_info {
        margin-top: 1rem;
    }
    div.data_save_area_1 > h1 {
        font-weight: 300;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        height: 92px;
        font-size: 16px;
        flex-direction: column;
        justify-content: flex-start;
        align-items: baseline;
    }
    div.data_save_area_1 {
        padding: 0 16px;
        margin-top: 1rem;
    }
    div.data_save_area_1 h1 span {
        font-size: 16px;
    }
    div.data_save_area_1 ul li span {
        font-size: 14px;
    }
    div.data_save_area_1 ul li {
        height: 45px;
    }
    div.data_save_area_1 ul li {
        padding-left: 0.875rem;
    }
    div.data_save_area_1 div.text_box_big h1 {
        height: 52px;
        font-size: 20px;
        padding-right: 1rem;
    }
    div.data_save_area_1 div.text_box_big h1 span {
        font-size: 20px;
    }
    div.response_prev_ui h1,
    div.response_prev_ui span {
        font-size: 12px;
    }
    div.response_prev_ui span {
        width: 90%;
        height: 50px;
        overflow-y: auto;
        word-break: break-all;
    }
    div.chat_data_inner {
        width: 100%;
        display: flex;
        flex-direction: column;
        min-height: 1px;
        gap: 10px;
        /* padding-bottom: 5rem; */
        /* max-height: calc(100vh - 235px); */
        padding-bottom: 67px;
        height: 100%;
        overflow: auto;
        overflow-x: hidden;
    }
    div.chat_row_2 div.chat_info div.chat_info_left > div > div > p {
        max-width: 80%;
        width: auto;
        font-size: 14px;
    }
    .chat_row_img_2_inner {
        max-width: calc(100% - 70px) !important;
        border-radius: 16px !important;
        width:auto;
    }
    div.chatting_area div.chat_data_area {
        height: calc(100vh - 62px);
        height: calc((var(--vh,1vh) * 100) - 62px);
        display: flex;
        flex-direction: column;
    }
    div.chatting_area div.chat_data_area div.line_notice::after {
        width: 100%;
    }
    .rest_chat_data_button {
        margin-left: 0;
        width: 100%;
    }
    div.chat_row_2 {
        padding-left: 0;
    }
    div.write_input_wrapper {
        width: 100%;
    }
    .chat span {
        width: 18px;
        height: 18px;
        background-color: #ff5555;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        border-radius: 100%;
        font-size: 10px;
        position: absolute;
        right: -5px;
        top: -8px;
        display: none;
    }
    div.chat_data_inner div.chat_row .chat_row_wrapper_my > p {
        max-width: 300px;
        word-break: keep-all;
        width: auto;
        word-break: break-all;
    }
    div.chatting_area {
        position: relative;
        width: 100%;
        left: 0;
        top: 0;
        height: auto;
        z-index: 9999;
        padding-left: 0;
        background: #fff;
        padding: 0 16px;
        display: none;
        z-index: 6;
    }
    .chat_wrapper {
        max-width: 100%;
        left: 0;
        overflow: auto;
        padding-bottom: 6rem;
        z-index: 11;
    }
    html,
    body {
        overflow: hidden !important;
    }
    .chat_wrapper.disabled {
        z-index: 6 !important;
    }
    div.left_menu_bar {
        max-width: 100%;
        height: 60px;
        width: 100%;
        position: fixed;
        left: 0;
        bottom: 0;
        top: inherit;
        z-index: 100;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        background: #fff;
        border-top: 1px solid #00000010;
        border-right: 0;
    }
    div.left_menu_bar.disabled {
        z-index: 6;
    }
    div.left_menu_bar ul {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
    }
    div.left_menu_bar ul li.user_profile {
        display: none;
    }
    div.left_menu_bar ul li a {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 30px;
        margin: 0 auto;
    }
    div.cal_guide ul {
        padding-right: 0;
    }
    div.chatting_area div.chatting_header .left {
        left: 0;
    }
    div.cal_guide h1 {
        overflow: hidden;
    }
}
