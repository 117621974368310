/* 2022-02-24 인수인계
팝업 css입니다. */

div.popup_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 9999;
}
div.popup_wrapper div.popup_box {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    position: relative;
    width: 300px;
    height: auto;
    background: #ffffff;
    box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.2);
    border-radius: 16px;
    z-index: 5;
    padding: 20px;
    padding-bottom: 4.5rem;
}
div.popup_wrapper div.popup_box div.popup_text_wrapper {
    width:100%;
}
#accountPw{
    height: 44px;
    background: #f2f2f2;
    border-radius: 8px;
    padding-left: 1rem;
    margin: 0;
    width: 100%;
    display: block;
}

.button_left {
    position: absolute;
    right: 106px;
    bottom: 29px;
    color: #a1a1a1;
    cursor: pointer;
    bottom: 12px;
    height: 52px;
    width: 92px;
}

div.popup_wrapper div.popup_box div.popup_text_wrapper h1 {
    font-size: 16px;
    color: #111111;
    margin-bottom: 23px;
    display: flex;
    align-items: center;
    align-items: c;
    justify-content: center;
    font-weight: 500;
}
div.popup_wrapper div.popup_box div.popup_text_wrapper span {
    color: #888888;
    font-size: 12px;
    text-align: center;
    width: 100%;
    display: inherit;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    word-break: keep-all;
}
div.popup_wrapper div.popup_box button.button_right {
    color: #4587f5;
    font-weight: 500;
    position: absolute;
    bottom: 12px;
    height: 52px;
    width: 92px;
    right: 16px;
}
div.popup_wrapper div.popup_box button.button_right:hover {
}
div.popup_wrapper div.popup_box button.button_right:active {
}
div.popup_wrapper div.popup_box button.button_right:focus {
}
div.popup_wrapper div.background_black {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #00000050;
    z-index: 4;
    left: 0;
    top: 0;
}

div.agree_popup_wrapper {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 6;
    background-color: #fff;
    padding: 0 20px;
    display: none;
    flex-direction: column;
    z-index: 100;
}
div.agree_popup_wrapper div.agree_header {
}
div.agree_popup_wrapper div.agree_header h1 {
    font-size: 16px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 68px;
    font-weight: 500;
}
div.agree_popup_wrapper div.agree_content {
    font-size: 14px;
    color: #888888;
    max-height: calc(100% - 160px);
    overflow-y: auto;
    height: 100%;
    line-height: 24px;
}

.agree_popup_wrapper button {
    position: absolute;
    right: 16px;
    cursor: pointer;
    bottom: 12px;
    width: 88px;
    height: 52px;
}
div.password_check_popup_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    height: 100%;
    display: none;
}
div.password_check_popup_wrapper div.password_check_popup_box {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    position: relative;
    background: #ffffff;
    box-shadow: 0px 3px 12px rgb(0 0 0 / 20%);
    border-radius: 16px;
    padding-bottom: 2rem;
    z-index: 6;
    width: auto;
    max-width: 90%;
    padding: 1.5rem 1.5rem;
    padding-top: 0.5rem;
}
div.password_check_popup_wrapper div.password_check_popup_box h1 {
    font-size: 16px;
    font-weight: 500;
    padding: 23px 0;
}
div.password_check_popup_wrapper div.password_check_popup_box input {
    height: 44px;
    background: #f2f2f2;
    border-radius: 8px;
    padding-left: 1rem;
    width: calc(100% - 30px);
    margin-bottom: 1.5rem;
    width: 100%;
}
div.password_check_popup_wrapper div.password_check_popup_box ul {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    padding-right: 1rem;
}
div.password_check_popup_wrapper div.password_check_popup_box ul li {
    font-size: 14px;
    cursor: pointer;
    width: 62px;
    text-align: center;
}
div.password_check_popup_wrapper div.password_check_popup_box ul li.blue {
}
div.password_check_popup_wrapper div.password_check_popup_wrapper_background_black {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #00000050;
}
div.payment_select_popup_wrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    top: 0;
    z-index: 99;
    display: none;
}
div.payment_select_popup_wrapper div.payment_select_popup_box {
    position: relative;
    z-index: 6;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    position: relative;
    max-width: 330px;
    height: auto;
    background: #ffffff;
    box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.2);
    border-radius: 16px;
    width: 90%;
    padding: 25px;
    padding-bottom: 0;
}
div.payment_select_popup_wrapper div.payment_select_popup_box h1 {
    font-size: 16px;
    font-weight: 800;
    text-align: center;
    margin-bottom: 25px;
}
div.payment_select_popup_wrapper div.payment_select_popup_box ul {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
}
div.payment_select_popup_wrapper div.payment_select_popup_box ul li.active {
    opacity: 1;
}
div.payment_select_popup_wrapper div.payment_select_popup_box ul li {
    width: 60px;
    height: 88px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 5px;
    background: #f7f7f7;
    cursor: pointer;
    border-radius: 8px;
    opacity: 0.3;
}
div.payment_select_popup_wrapper div.payment_select_popup_box ul p {
}
div.payment_select_popup_wrapper div.payment_select_popup_box ul p img {
}
div.payment_select_popup_wrapper div.payment_select_popup_box ul span {
}
div.payment_select_popup_wrapper div.payment_select_popup_box ul p {
}
div.payment_select_popup_wrapper div.payment_select_popup_box ul p img {
}
div.payment_select_popup_wrapper div.payment_select_popup_box ul span {
    font-size: 10px;
}

.payment_select_popup_wrapper_background_black {
    position: absolute;
    left: 0;
    top: 0;
    background: #00000050;
    width: 100%;
    height: 100%;
    z-index: 4;
}

.payment_select_popup_box_button_wrapper {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    margin-top: 1rem;
    padding-bottom: 1rem;
}
.payment_select_popup_box_button_wrapper button {
    height: 52px;
    font-size: 14px;
    width: 66px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.payment_select_popup_box_button_wrapper button:nth-of-type(1) {
    color: #888888;
}
.payment_select_popup_box_button_wrapper button:nth-of-type(2) {
    color: #4587f5;
}

.addrPopup{position: fixed;top: 50%;left: 50%;transform: translate(-50%,-50%);z-index: 9999;background: #fff;border: 1px solid #f1f1f1;display: none;width: 100%;max-width: 550px;max-height: 537px;height: 100%;}
.addrPopupCloseBox{display: flex;align-items: center;justify-content: flex-end;padding: 10px;border-bottom: 1px solid #f1f1f1}
.addrPopup>div:not(.addrPopupCloseBox){width: 100% !important;height: 100% !important;}

.myChatMenuPopupItem{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    width: 100%;
}
.myChatMenuPopupItem .create_col{
    display: flex;
    flex-direction: column;
    gap: 2px;
}
.myChatMenuPopupItem span{
    font-size: 12px !important;
    color: #888888 !important;
    text-align: left !important;
}
.myChatMenuPopupItem h2{
    font-size: 14px;
    text-align: left;
}    