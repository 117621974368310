/* 2022-02-24 인수인계
약관동의 페이지 css입니다. */

div.agree_wrapper {
    width: 100%;
    padding-bottom: 160px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
div.agree_wrapper h1 {
    font-weight: 500;
    text-align: center;
    font-size: 40px;
    margin-bottom: 100px;
}
div.agree_wrapper ul.active {
    height: auto;
}
div.agree_wrapper ul {
    max-width: 480px;
    width: calc(100% - 32px);
    margin: 0 auto;
    height: 52px;
    overflow: hidden;
}
div.agree_wrapper ul.active li:nth-of-type(1) {
    background: url(../images/icon_pointer_bottom.svg) no-repeat;
    background-position: 96%;
}
div.agree_wrapper ul li {
    width: 100%;
    height: 44px;
    display: flex;
    align-items: center;
    padding-left: 24px;
    cursor: pointer;
    background: url(../images/icon_pointer_right.svg) no-repeat;
    background-position: 96%;
    background-color: #f2f2f2;
}
div.agree_wrapper ul li label {
    display: flex;
    align-items: center;
    gap: 12px;
    width: auto;
    cursor: pointer;
}
div.agree_wrapper ul li input {
    width: 28px;
    height: 28px;
    border: 1px solid #dddddd;
    border-radius: 100%;
    pointer-events: none;
}

div.agree_wrapper ul li input:checked {
    background: url(../images/icon_checked.svg) no-repeat;
    background-size: cover;
}

div.agree_wrapper ul li span {
    font-size: 14px;
}
div.agree_wrapper button.active {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    position: static;
    width: 160px;
    height: 44px;
    left: 880px;
    top: 32px;
    background: #4587f5;
    border-radius: 1000px;

    /* Inside Auto Layout */
    margin: 8px 0px;
    color: #fff;
    font-size: 14px;
    margin-top: 40px;
}
div.agree_wrapper button.active:hover {
}
div.agree_wrapper button.active:active {
}
div.agree_wrapper button.active:focus {
}
div.agree_wrapper button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    position: static;
    width: 160px;
    height: 44px;
    left: 880px;
    top: 32px;
    border-radius: 1000px;

    /* Inside Auto Layout */
    margin: 8px 0px;
    color: #fff;
    font-size: 14px;
    margin-top: 40px;
    background: #dddddd;
}
div.agree_wrapper button:hover {
}
div.agree_wrapper button:active {
}
div.agree_wrapper button:focus {
}

div.agree_wrapper ul li:nth-of-type(1) {
    height: 52px;
    padding-left: 14px;
    background: url(../images/icon_pointer.svg) no-repeat;
    background-position: 96%;
}
div.agree_wrapper ul li:nth-of-type(1) span {
}
div.agree_wrapper ul li:nth-of-type(1) label {
}

@media (max-width: 767px) {
    div.agree_wrapper {
        margin-top: 8rem;
    }
    div.agree_wrapper h1 {
        font-size: 24px;
        margin-bottom: 50px;
    }
    div.agree_wrapper ul {
        width: 100%;
    }
}
